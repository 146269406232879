import { HttpClient } from '@angular/common/http';
import { Information } from '../models/core/information';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { informationData } from '../data/informations-data';


@Injectable()
export class InformationService {

    constructor(private http: HttpClient) {

    }

    getHistory(): Observable<Information[]> {
        return of (informationData);
    }
}
