import { HomeComponent } from '../eshop-module/home/home.component';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

export const sharedModuleRoutes: Routes = [
];



@NgModule({
    imports: [
        RouterModule.forChild(sharedModuleRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class SharedRoutingModule {

}
