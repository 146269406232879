import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EshopModuleModule } from './eshop-module/eshop-module.module';
import { SharedModuleModule } from './shared-module/shared-module.module';
import { ArticleModuleModule } from './article-module/article-module.module';
import { AuthService } from './shared-module/services/auth.service';
import { CustomersModule } from './customers-module/customers-module.module';
import { AuthInterceptor } from './shared-module/interceptors/auth.interceptor';
import {  HTTP_INTERCEPTORS } from '@angular/common/http';

export function getAppCredentials(authorizationService: AuthService) {
  return () => authorizationService.getAppCredentials()
    // .toPromise()
    .subscribe(res => {
      const token = res;
      localStorage.setItem('audiance_token', token.access_token);
    }, error => console.log(error));
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModuleModule,
    EshopModuleModule,
    // ArticleModuleModule,
    // CustomersModule,

  ],
  providers: [
    // { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: getAppCredentials,
      deps: [AuthService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
