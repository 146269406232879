import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Contact } from '../models/core/contact';
import { Observable } from 'rxjs';
import { WS_URL } from 'src/environments/environment';


@Injectable()
export class ContactService {

    constructor(private http: HttpClient) {

    }
    SendContact(contact: Contact): Observable<Response> {
        return this.http
            .post<Response>(WS_URL.contact.sendMail, contact);
    }
}
