
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WS_URL } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { SalesDocument } from '../models/payment/sales_document';
import { Injectable } from '@angular/core';
import { SalesDocumentResult } from '../models/payment/sales_document_result';
import { Payment } from '../models/payment/Payment';

@Injectable()
export class SalesDocumentService {



    constructor(private http: HttpClient) { }

    create(salesDocument: SalesDocument): Observable<SalesDocumentResult> {
        return this.http
            .post<SalesDocumentResult>(WS_URL.saledocument.addUrl, salesDocument);
    }

    cancel(salesDocument: SalesDocument): Observable<SalesDocument> {
        return this.http
            .post<SalesDocument>(WS_URL.saledocument.cancelUrl, salesDocument);
    }
    close(salesDocument: SalesDocument): Observable<SalesDocument> {
        return this.http
            .post<SalesDocument>(WS_URL.saledocument.closeUrl, salesDocument);
    }


    lock(salesDocument: SalesDocument): Observable<SalesDocument> {
        return this.http
            .post<SalesDocument>(WS_URL.saledocument.lockUrl, salesDocument);


    }


    unlock(salesDocument: SalesDocument): Observable<SalesDocument> {
        return this.http
            .post<SalesDocument>(WS_URL.saledocument.unlockUrl, salesDocument);
    }

    getByKey(salesDocument: SalesDocument): Observable<SalesDocument> {
        return this.http
            .post<SalesDocument>(WS_URL.saledocument.getByKeyUrl, salesDocument);

    }

    getByReference(salesDocument: SalesDocument): Observable<SalesDocument> {
        return this.http
            .post<SalesDocument>(WS_URL.saledocument.getByReferenceUrl, salesDocument);

    }


    getByExternalReference(salesDocument: SalesDocument): Observable<SalesDocument> {
        return this.http
            .post<SalesDocument>(WS_URL.saledocument.getByExternalReferenceUrl, salesDocument);

    }


    getHeaderList(salesDocument: SalesDocument): Observable<SalesDocument[]> {

        return this.http
            .post<SalesDocument[]>(WS_URL.saledocument.getHeaderListUrl, salesDocument)

            .pipe(map(res => {
                if (res != undefined) {
                    localStorage.removeItem('documents');
                    localStorage.setItem('documents', JSON.stringify(res));

                }
                return res;
            }));
    }


    updateDocument(salesDocument: SalesDocument): Observable<SalesDocument> {
        return this.http
            .post<SalesDocument>(WS_URL.saledocument.updateDocumentUrl, salesDocument);

    }

    updateDocumentHeader(salesDocument: SalesDocument): Observable<SalesDocument> {
        return this.http
            .post<SalesDocument>(WS_URL.saledocument.updateDocumentHeaderUrl, salesDocument);

    }


    updatePaymentDocument(salesDocument: SalesDocument): Observable<SalesDocument> {
        return this.http
            .post<SalesDocument>(WS_URL.saledocument.updatePaymentDocumentUrl, salesDocument);

    }

    sendSalesDocumentByEmail(salesdocuent: SalesDocument): Observable<any> {
        salesdocuent.date = new Date();
        salesdocuent.date.setHours(salesdocuent.date.getHours() + 1);
        return this.http
            .post(WS_URL.saledocument.sendSalesDocumentByEmailUrl, salesdocuent);


    }

    setSalesDocumentLocalStorage(salesDoc: SalesDocument) {
        localStorage.setItem('SalesDocument', JSON.stringify(salesDoc));
    }

    removeSalesDocumentLocalStorage() {
        localStorage.removeItem('SalesDocument');
    }

    getSalesDocumentLocalStorage(): SalesDocument {
        return JSON.parse(localStorage.getItem('SalesDocument'));
    }

    savePayment(payment: Payment): Observable<Payment> {
        return this.http
            .post<Payment>(WS_URL.saledocument.savePaymentUrl, payment);

    }
}
