export const environment = {
  production: false
};



export const API_BASE_URL = '';
// export const API_BASE_URL = 'http://petit-bateau.tn/';

export const WS_URL = {

  auth: {
    token: API_BASE_URL + 'api/token'
  },
  account: {
    returnUrl: '/',
    changepasswordUrl: API_BASE_URL + 'api/account/changepassword',
    modifyEmailUrl: API_BASE_URL + 'api/account/ChangeEmail',
    resetPasswordTokenUrl: API_BASE_URL + 'api/Account/resetPassword',
    getUserByEmailUrl: API_BASE_URL + 'api/Account/by/email',
    forgotPasswordTokenUrl: API_BASE_URL + 'api/Account/forgotPassword',
    checkPasswordUrl: API_BASE_URL + 'api/Account/password/check',
    emailExistsUrl: API_BASE_URL + 'api/Account/email/exists',
    registerUrl: API_BASE_URL + 'api/Account/register',
    searchUserByPhoneNumberUrl: API_BASE_URL + 'api/Account/searchUserByPhoneNumber',
    confirmCustomerEmailUrl: API_BASE_URL + '/api/customer/confirmCustomerEmail',
  },
  loyaulty: {
    loyaultyEndPoint: API_BASE_URL + 'api/loyaulty/avaible',
  },
  article: {
    articlesbyCategoriesURL: API_BASE_URL + 'api/article/by/categories',
    articlesbyCategoriesLevelOneURL: API_BASE_URL + 'api/article/by/categoriesLevelOne',
    articlesbyCategoriesLevel2URL: API_BASE_URL + 'api/article/by/categoriesLevel2',
    getArticlesFromAllCategoriesURL: API_BASE_URL + 'api/article/by/AllCategories',
    updateShoppingCartURL: API_BASE_URL + 'api/article/updateShoppingCart',
    resetShoppingCartURL: API_BASE_URL + 'api/article/resetShoppingCart',
    articlesByCodeURL: API_BASE_URL + 'api/article/by/familycode',
    availableQuantityURL: API_BASE_URL + 'api/iteminventory/get_avilable_qtty',
    itemInventoryUrl: API_BASE_URL + 'api/iteminventory/getlist',
    getarticlesbycodearresURL: API_BASE_URL + 'api/article/get/code',
    recommendedArticleURL: API_BASE_URL + 'api/article/get/recommended',
    associatedArticleURL: API_BASE_URL + 'api/article/get/associated',
    getXCollectionsURL: API_BASE_URL + 'api/article/get/collections',
    allPromotional: API_BASE_URL + 'api/article/level/Allpromotion'
  },
  categories: {
    getAll: API_BASE_URL + 'api/article/level/all',
    getPromotional: API_BASE_URL + 'api/article/level/promotion',
    categoryUpdateList: API_BASE_URL + 'api/article/categories/update',
  },
  customer: {
    addUrl: API_BASE_URL + 'api/customer/add',
    getcustomerIdByReferenceUrl: API_BASE_URL + 'api/customer/get/reference',
    getDetailsUrl: API_BASE_URL + 'api/customer/details/get/',
    updateUrl: API_BASE_URL + 'api/customer/update',
    getByEmailUrl: API_BASE_URL + 'api/customer/get/email',
    searchCustomerIdsUrl: API_BASE_URL + 'api/customer/searchCustomerIds'
  },
  search: {
    articles: API_BASE_URL + 'api/search/articles',
    articleGet: API_BASE_URL + 'api/search/get/articles',
    categories: API_BASE_URL + 'api/search/categories',
  },
  homeSection: {
    get: API_BASE_URL + 'api/HomeSection/get',
    update: API_BASE_URL + 'api/HomeSection/update',
    getHomeSectionCategories: API_BASE_URL + 'api/HomeSection/getHomeSectionsCategories'
  },
  wishList: {
    addToWishListUrl: API_BASE_URL + 'api/wishlist/add',
    updateWishListUrl: API_BASE_URL + 'api/wishlist/update',
    removeFromWishListUrl: API_BASE_URL + 'api/wishlist/remove',
    removeFromWishListAllUrl: API_BASE_URL + 'api/wishlist/remove/all',
    getAllUrl: API_BASE_URL + 'api/wishlist/get',
    sendEmailUrl: API_BASE_URL + 'api/wishlist/email',
  },
  country: {
    getAll: API_BASE_URL + 'api/country/all'
  },
  shoppingCart: {
    getSucStoresUrl: API_BASE_URL + 'api/Store/getStoresByType',
    getAvilableQttyListUrl: API_BASE_URL + 'api/iteminventory/getAvilableQttyList',
    PaymentCredentialUrlAPI: API_BASE_URL + 'api/payment/doregister',
  },
  clickToPayAPI: {
    //  REGISTER_URL: 'https://test.clictopay.com/payment/rest/register.do',
    REGISTER_URL: 'https://ipay.clictopay.com/payment/rest/register.do',
    API_USER_LOGIN: '1482056458', // TODO
    API_USER_PASSWORD: 'Df9w2Cd9M' // TODO
  },
  basket: {
    getCurrentBasketURL: API_BASE_URL + 'api/basket/current/getBasket',
    addCommandesUrl: API_BASE_URL + 'api/basket/addCommandes',
    addCommandeUrl: API_BASE_URL + 'api/basket/addCommande',
    updateBasketUrl: API_BASE_URL + 'api/basket/updateBasket',
    removeCommandeUrl: API_BASE_URL + 'api/basket/removeCommande',
    updateCommandeUrl: API_BASE_URL + 'api/basket/updateCommande',
    removeCommandesUrl: API_BASE_URL + 'api/basket/removeCommandes',
    validateBasketUrl: API_BASE_URL + 'api/basket/validateBasket',
  },

  saledocument: {
    addUrl: API_BASE_URL + 'api/sales/document/add',
    sendSalesDocumentByEmailUrl: API_BASE_URL + 'api/sales/document/sendEmail',
    cancelUrl: API_BASE_URL + 'api/sales/document/cancel',
    closeUrl: API_BASE_URL + 'api/sales/document/close',
    lockUrl: API_BASE_URL + 'api/sales/document/lock',
    unlockUrl: API_BASE_URL + 'api/sales/document/unlock',
    getByKeyUrl: API_BASE_URL + 'api/sales/document/get/By/Key',
    getByReferenceUrl: API_BASE_URL + 'api/sales/document/get/By/Reference',
    getByExternalReferenceUrl: API_BASE_URL + 'api/sales/document/get/By/External/Reference',
    getHeaderListUrl: API_BASE_URL + 'api/sales/document/get/header/list',
    updateDocumentUrl: API_BASE_URL + 'api/sales/document/update',
    updateDocumentHeaderUrl: API_BASE_URL + 'api/sales/document/update/header',
    updatePaymentDocumentUrl: API_BASE_URL + 'api/sales/document/update/payment',
    savePaymentUrl: API_BASE_URL + 'api/payment/save',
  },
  contact: {
    sendMail: API_BASE_URL + 'api/contact/sendMail'
  },
  dimension: {
    getAll: API_BASE_URL + 'api/dimension/all'
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error',  // Included with Angular CLI.
