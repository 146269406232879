import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SliderItem } from '../../models/core/SliderItem';

@Injectable()
export class MatieresFinitionsSliderSrvice {
    public getSliderItems(): Observable<SliderItem[]> {
        const list: SliderItem[] = [{
            id: 0,
            imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dw4b9c990b/images/Pages_contenu/2018/Marque/VISU_FR/MATIERES_bouclette-velours.jpg',
            title: 'La bouclette velours',
            description: 'Le pyjama en bouclette velours voit le jour en 1960 dans les ateliers Petit Bateau. En plus d’être pratique, sa matière très souple et d’une douceur inégalée, ne bouge pas au lavage tout en tenant bien chaud.',
            SliderSection_Id: 0
        }, {
            id: 1,
                imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dw13c09728/images/Pages_contenu/2018/Marque/VISU_FR/MATIERES_bouclette-eponge.jpg',
            title: 'La bouclette éponge',
            description: 'Le point picot forme une mini-boucle libre. On craque pour cette finition raffinée et élégante.',
            SliderSection_Id: 0
        },
        {
            id: 2,
            imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dw0617a8a4/images/Pages_contenu/2018/Marque/VISU_FR/MATIERES_point-picot.jpg',
            title: 'Le Point Picot',
            description: 'Le point picot forme une mini-boucle libre. On craque pour cette finition raffinée et élégante.',
            SliderSection_Id: 0
        },
        {
            id: 2,
            imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dw59c18d19/images/Pages_contenu/2018/Marque/VISU_FR/MATIERES_point-cocotte.jpg',
            title: 'Le Point Cocotte',
            description: 'Le point cocotte est composé de points serrés réalisés par une machine spécialement inventée par Petit Bateau dans les années 60.',
            SliderSection_Id: 0
        },
        {
            id: 3,
            imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dw18474ceb/images/Pages_contenu/2018/Marque/VISU_FR/MATIERES_milleraies.jpg',
            title: 'Le Milleraies',
            description: 'Ce tricot deux fils inventé par Petit Bateau en 1970 nécessite des métiers à tricoter spéciaux. Et pourquoi ce nom « milleraies » ? Parce qu’il y a mille rayures au mètre de tissu, pardi !',
            SliderSection_Id: 0
        }];
        return of(list);
    }


}
