import { Dimension } from './dimension';
import { Category } from './category';
import { Tarif } from './tarif';
import { Promotion } from './promotion';
import { Family } from './family';


export class Article {


    id: number;
    codeArticle: string;
    codeFamily: string;
    codeBarre: string;
    public creationDate: Date;
    public updateDate: Date;
    public collection: string;
    libelle: string;
    basePrice: number;
    promoPrice: number;
    quantity: number;
    origin: string;
    dimensions: Dimension[];
    description: string;
    imgReference: string;
    reference: string;

    sizeFalimyCode: string;
    sizeCode: string;
    size: string;
    colorFamilyCode: string;
    colorCode: string;
    color: string;
    storeIds: string[];
    tarifPrice: number;
    articlePrice: number;

    categories: Category[];
    tarif: Tarif;
    promotion: Promotion;
    family: Family;

    isSelected = false;
    dimentionIsOpen = false;
    public getDimensionByCodeType(codeType: string): Dimension {
        return this.dimensions.find(p => p.codeType == codeType);
    }




}
