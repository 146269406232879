import { Injectable } from '@angular/core';
import { Category, Article } from '../models/articles';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment, WS_URL } from 'src/environments/environment';

@Injectable({
    providedIn : 'root'
  })
export class SharedCategoryService {

     categoryUpdatePopupIsVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
     categoriesHasChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient) { }

    getSortedCategories(categories: Category[] | undefined, sortChilddren: boolean = false): Category[] {

        if (categories != undefined && categories != null && categories.length > 0) {

            categories = categories.sort((a1, a2) => {
                return (a1.rank > a2.rank) ? 1 : (a1.rank < a2.rank) ? -1 : (a1.libelle > a2.libelle) ? 1 : (a1.libelle < a2.libelle) ? -1 : 0;
            });
            if (sortChilddren == true) {
                categories.forEach(x => { x.children = this.getSortedCategories(x.children, true); });
            }
        }
        return categories;
    }

    sortByAlphabitique(cats: Category[]): Category[] {
        cats.sort((a, b) => 0 - (a.libelle > b.libelle ? 1 : -1));
        return cats;
    }

     displayCategoryUpdatePopup(value: boolean) {
        this.categoryUpdatePopupIsVisible.next(value);

    }

    saveCategories(categories: Category[]) {
        localStorage.removeItem('categories');
        localStorage.setItem('categories', JSON.stringify(categories));
    }
    getSavedCategories(): Category[] {
        if (localStorage.getItem('categories') != undefined) {
            return JSON.parse(localStorage.getItem('categories'));
        }
        return null;
    }

    getCategories(): Observable<Category[]> {
        return this.http.get<Category[]>(WS_URL.categories.getAll);
    }

    getCategoriesPromotional(): Observable<Category[]> {
        return this.http.get<Category[]>(WS_URL.categories.getPromotional);
    }

    getcategoryById(id: number): Category {
        const cats: Category[] = this.getSavedCategories();
        let cat: Category;
        if (cats != undefined && cats != null) {
            cat = cats.find(p => p.id == id);
            if (cat == undefined || cats == null) {
                cats.forEach(c => {
                    if (c.children != null && c.children.length > 0 && cat == null) {
                        cat = c.children.find(p => p.id == id);
                        if (cat == undefined || cats == null) {
                            c.children.forEach(p => {
                                if (p.children != null && p.children.length > 0 && cat == null) {
                                    cat = p.children.find(k => k.id == id);
                                }
                            });

                        }
                    }
                });
            }
        }
        return cat;
    }

    getArticleCategoriesCodeList(article: Article): number[] {
        const cats: Category[] = this.getSavedCategories();
        const cat = article.categories.reduce((p, c) => p.id > c.id ? p : c);
        const categorieId = cat.id;
        let CategoriesCodes: number[] = [];
        cats.forEach(c1 => {
            if (c1.children != null && c1.children.length) {
                if (c1.id == categorieId) {
                    CategoriesCodes = [];
                    CategoriesCodes.push(c1.id);
                }
                c1.children.forEach(c2 => {
                    if (c2.id == categorieId) {
                        CategoriesCodes = [];
                        CategoriesCodes.push(c1.id);
                        CategoriesCodes.push(c2.id);
                    }
                    if (c2.children != null && c2.children.length) {
                        c2.children.forEach(c3 => {
                            if (c3.id == categorieId) {
                                CategoriesCodes = [];
                                CategoriesCodes.push(c1.id);
                                CategoriesCodes.push(c2.id);
                                CategoriesCodes.push(c3.id);
                            }
                        });
                    }
                });
            }
        });
        return CategoriesCodes;
    }
     updateCategories(categories: Category[]): Observable<any> {
        return this.http
            .post(WS_URL.categories.categoryUpdateList, categories) as any;
    }


}
