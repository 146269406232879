import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminCategoryComponent } from './admin-category/admin-category.component';

@NgModule({
  declarations: [AdminCategoryComponent],
  imports: [
    CommonModule ,

  ],
  exports: [AdminCategoryComponent],

  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]

})
export class AdminModuleModule { }
