import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HomeSection } from '../models/e-shop';
import { Observable } from 'rxjs';




@Injectable()
export class FileStreamManager {


    constructor(private http: HttpClient) { }


    public upload(fileToUpload: any, fileType: string, Id: number): Observable<HomeSection> {

        const input = new FormData();
        input.append('file', fileToUpload);
        return this.http
            .post<HomeSection>('/api/HomeSection/upload?fileType=' + fileType + '&id=' + Id, input);

    }

}
