import { SliderItem } from '../../models/core/SliderItem';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';



@Injectable()
export class MarqueSecondLifeSliderService {


    public getSliderItems(): Observable<SliderItem[]> {
        const list: SliderItem[] = [{
            id: 0,
            redirectUrl: 'https://www.youtube.com/watch?v=CYpjfZD36Hs',
            imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dw10c8f107/images/Pages_contenu/2018/Marque/VISU_FR/SECONDE-VIE_apprentis-couturier.jpg',
            SliderSection_Id: 0,
        }, {
            id: 1,
            redirectUrl: 'https://www.youtube.com/watch?v=nyneccLLdmo',
            imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dwcdc80bd8/images/Pages_contenu/2018/Marque/VISU_FR/SECONDE-VIE_exces-de-gourmandise.jpg',
            SliderSection_Id: 0
        }, {
            id: 2,
            redirectUrl: 'https://www.youtube.com/watch?v=muPYSJFETas',
            imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dw9dbd8347/images/Pages_contenu/2018/Marque/VISU_FR/SECONDE-VIE_cascadeur-en-herbe.jpg',
            SliderSection_Id: 0
        }, {
            id: 3,
            redirectUrl: 'https://www.youtube.com/watch?v=B1IX-obxEV0',
            imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dwd021489a/images/Pages_contenu/2018/Marque/VISU_FR/SECONDE-VIE_debordement-d-amour.jpg',
            SliderSection_Id: 0
        }, {
            id: 4,
            redirectUrl: 'https://www.youtube.com/watch?v=zqfq2CCn3rI',
            imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dw11675729/images/Pages_contenu/2018/Marque/VISU_FR/SECONDE-VIE_aventuriers-des-bois.jpg',
            SliderSection_Id: 0
        },
        {
            id: 5,
            redirectUrl: 'https://www.youtube.com/watch?v=QaJXHxHSisc',
            imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dwb5da98ef/images/Pages_contenu/2018/Marque/VISU_FR/SECONDE-VIE_essai-artistiquejpg.jpg',
            SliderSection_Id: 0
        },
        {
            id: 6,
            redirectUrl: 'https://www.youtube.com/watch?v=CYpjfZD36Hs',
            imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dw10c8f107/images/Pages_contenu/2018/Marque/VISU_FR/SECONDE-VIE_apprentis-couturier.jpg',
            SliderSection_Id: 0
        },
        {
            id: 7,
            redirectUrl: 'https://www.youtube.com/watch?v=nyneccLLdmo',
            imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dwcdc80bd8/images/Pages_contenu/2018/Marque/VISU_FR/SECONDE-VIE_exces-de-gourmandise.jpg',
            SliderSection_Id: 0
        },
        {
            id: 8,
            redirectUrl: 'https://www.youtube.com/watch?v=muPYSJFETas',
            imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dw9dbd8347/images/Pages_contenu/2018/Marque/VISU_FR/SECONDE-VIE_cascadeur-en-herbe.jpg',
            SliderSection_Id: 0
        },
        {
            id: 9,
            redirectUrl: 'https://www.youtube.com/watch?v=B1IX-obxEV0',
            imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dwd021489a/images/Pages_contenu/2018/Marque/VISU_FR/SECONDE-VIE_debordement-d-amour.jpg',
            SliderSection_Id: 0
        },
        {
            id: 10,
            redirectUrl: 'https://www.youtube.com/watch?v=zqfq2CCn3rI',
            imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dw11675729/images/Pages_contenu/2018/Marque/VISU_FR/SECONDE-VIE_aventuriers-des-bois.jpg',
            SliderSection_Id: 0
        },
        {
            id: 11,
            redirectUrl: 'https://www.youtube.com/watch?v=QaJXHxHSisc',
            imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dwb5da98ef/images/Pages_contenu/2018/Marque/VISU_FR/SECONDE-VIE_essai-artistiquejpg.jpg',
            SliderSection_Id: 0
        },
        {
            id: 12,
            redirectUrl: 'https://www.youtube.com/watch?v=CYpjfZD36Hs',
            imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dw10c8f107/images/Pages_contenu/2018/Marque/VISU_FR/SECONDE-VIE_apprentis-couturier.jpg',
            SliderSection_Id: 0
        }    ];
        return of( list);
    }





}
