import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { SliderItem } from '../../models/core/SliderItem';




@Injectable()
export class MarqueYoutubeVideoSliderService {


    public getSliderItems(): Observable<SliderItem[]> {
        const list: SliderItem[] = [{
            id: 0,
            redirectUrl: 'https://www.youtube.com/watch?v=rMhwWpw-uEs',
            imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dw6758435b/images/Pages_contenu/2018/Marque/VISU_FR/SAVOIR-FAIRE_creation.jpg',
            SliderSection_Id: 0
        }, {
            id: 1,
            redirectUrl: 'https://www.youtube.com/watch?v=T5HaeMVpuko',
            imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dw5c943f0e/images/Pages_contenu/2018/Marque/VISU_FR/SAVOIR-FAIRE_tricotage.jpg',
            SliderSection_Id: 0
        }, {
            id: 2,
            redirectUrl: 'https://www.youtube.com/watch?v=PfHcPpKmP4Y',
            imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dw581e0280/images/Pages_contenu/2018/Marque/VISU_FR/SAVOIR-FAIRE_teinture.jpg',
            SliderSection_Id: 0
        }, {
            id: 3,
            redirectUrl: 'https://www.youtube.com/watch?v=hKi1dhsU2PU',
            imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dwd6509148/images/Pages_contenu/2018/Marque/VISU_FR/SAVOIR-FAIRE_confection.jpg',
            SliderSection_Id: 0
        }, {
            id: 4,
            redirectUrl: 'https://www.youtube.com/watch?v=PEdUpplN6Ws',
            imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dw6b7d1a83/images/Pages_contenu/2018/Marque/VISU_FR/SAVOIR-FAIRE_serigraphie-broderie.jpg',
            SliderSection_Id: 0
        }, {
            id: 5,
            redirectUrl: 'https://www.youtube.com/watch?v=6h7BEzTANXA',
            imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dw1fc19258/images/Pages_contenu/2018/Marque/VISU_FR/SAVOIR-FAIRE_qualite.jpg',
            SliderSection_Id: 0
        }];
        return of( list);
    }





}
