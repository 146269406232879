import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { AdvertisingBannerComponent } from './advertising-banner/advertising-banner.component';
import { ShoppingCartIconComponent } from './shopping-cart-icon/shopping-cart-icon.component';
import { SearchComponent } from './search/search.component';
import { CategoryMenuComponent } from './category-menu/category-menu.component';
import { SharedModuleModule } from '../shared-module/shared-module.module';
import { AdminUpdateComponent } from './admin-update/admin.update.component';
import { EShopRoutingModule } from './eshop-routing.module';
import { FormsModule } from '@angular/forms';
import { AdminModuleModule } from '../admin-module/admin-module.module';

@NgModule({
  declarations: [HomeComponent,
    FooterComponent,
    HeaderComponent,
    AdvertisingBannerComponent,
    ShoppingCartIconComponent,
    SearchComponent,
    CategoryMenuComponent,
    AdminUpdateComponent
  ],
  imports: [
    CommonModule,
    FormsModule ,
    SharedModuleModule,
    EShopRoutingModule,
    AdminModuleModule
  ],
  exports: [FooterComponent,
    HeaderComponent,
    AdvertisingBannerComponent,
    HomeComponent],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]

})

export class EshopModuleModule { }
