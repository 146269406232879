

export class Tarif {

    id: number;
    codeArticle: string;
    price: number;
    startDate: Date;
    endDate: Date;
}
