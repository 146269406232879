

import { Injectable } from '@angular/core';
import { Article, ArticleStoreAvailableQty, Dimension, Collection, Category } from '../models/articles';
import { Observable, BehaviorSubject } from 'rxjs';
import { GlobalsService } from './globals.service';
import { LoaderService } from './loader.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { WS_URL } from 'src/environments/environment';
import { SharedCategoryService } from './shared.category.service';
import { Commande } from '../models/shopping-cart/commande';
import { HttpParamsOptions } from '@angular/common/http/src/params';




@Injectable({
    providedIn: 'root'
})
export class SharedArticleService {

    public isLoadingSizes = false;
    constructor(public http: HttpClient,
                public globals: GlobalsService,
                public loaderservice: LoaderService,
                public categoryservice: SharedCategoryService) { }


    getCmdArticles(items: Commande[]): Article[] {

        return (items && items.length > 0) ? items.map(x => {
            const article = Object.assign({}, x.article);
            article.quantity = x.quantity;
            return article;
        }) : [];
    }

    getPriceAfterPromotion(article: Article): number {
        return (article.promotion != undefined && article.promotion.remise > 0) ?
            article.basePrice - (article.basePrice * article.promotion.remise / 100) : article.basePrice;
    }
    getSum(articles: Article[]): number {
        let sum = 0;
        (articles && articles.length > 0) ? articles.forEach(p => sum += (this.getPriceAfterPromotion(p) * p.quantity)) : [];
        return sum;
    }
    getArticleByFamilyCode(codeFamily: string): Observable<Article[]> {


        let params: HttpParams = new HttpParams();
        params = params.set('id', codeFamily);
        return this.http
            .get<Article[]>(WS_URL.article.articlesByCodeURL, { params });
    }

    public getArticleByCategories(codes: number[],
                                  hasPromotion: boolean = false,
                                  storeIds: string[] = [this.globals.storeId],
                                  allAvailableWarehouse: boolean = false,
                                  detailSku: boolean = true,
                                  onlyAvailableStock: boolean = false,
                                  withStoreName: boolean = true,
                                  pageIndex: number = 1,
                                  pageSize: number = 20): Observable<Article[]> {



        const obj = {
            codes,
            storeIds,
            allAvailableWarehouse,
            detailSku,
            onlyAvailableStock,
            withStoreName,
            pageIndex,
            pageSize,
            hasPromotion
        };

        return this.http
            .post<Article[]>(WS_URL.article.articlesbyCategoriesURL, obj);

    }

    getAssociatedArticles(codebarre: string): Observable<Article[]> {
        let params: HttpParams = new HttpParams();
        params = params.append('codebarre', codebarre);
        return this.http
            .get<Article[]>(WS_URL.article.associatedArticleURL, { params });
    }


    public getArticleStoreAvailableQty(artcileIdentifiers: string[],
                                       storeIds: string[] = [this.globals.sotreMarsaId, this.globals.storeIdManzah5, this.globals.storeIdWeb],
                                       allAvailableWarehouse: boolean = true,
                                       detailSku: boolean = true,
                                       onlyAvailableStock: boolean = false,
                                       withStoreName: boolean = true,
                                       pageIndex: number = 1,
                                       pageSize: number = 20): Observable<ArticleStoreAvailableQty[]> {


        const obj = {
            artcileIdentifiers,
            storeIds,
            allAvailableWarehouse,
            detailSku,
            onlyAvailableStock,
            withStoreName,
            pageIndex,
            pageSize
        };

        return this.http
            .post<ArticleStoreAvailableQty[]>(WS_URL.article.itemInventoryUrl, obj);
    }


    getCategoriesUrlRouter(article: Article, categoriesCode: number[] = []): any[] {

        let CategoriesCodes: number[] = categoriesCode;
        if (!categoriesCode || categoriesCode.length <= 0) {
            CategoriesCodes = this.categoryservice.getArticleCategoriesCodeList(article);
        }

        const urlDetailsParam = ['/article/details/', article.codeFamily];
        CategoriesCodes.forEach(x => urlDetailsParam.push(('' + x)));
        return urlDetailsParam;
    }
    getAllCategoriesPromotional(): Observable<Article[]> {
        return this.http.get<Article[]>(WS_URL.article.allPromotional);

    }

    getArticleDimensionByCodeType(article: Article, codeType: string): Dimension {
        const dim = article.dimensions.find(p => p.codeType == codeType);
        return dim;
    }
    getArticlesByColor(articles: Article[], color: Dimension): Article[] {
        return articles.filter(p => p.dimensions.find(dim => dim.code === color.code && dim.codeType === color.codeType && dim.libelle === color.libelle && dim.codeFamily === color.codeFamily) != undefined);
    }



    getStoresAvailableQuantity(articleStoreAvailableQty: ArticleStoreAvailableQty[], article: Article) {
        let storesAvailableQte = 0;

        if (articleStoreAvailableQty != undefined && articleStoreAvailableQty.length > 0) {
            const storeAvailableQteList = articleStoreAvailableQty.filter(saq => saq.articleFamilyCode == article.codeFamily
                && saq.dimension1 == article.size && saq.dimension2 == article.color);
            storeAvailableQteList.map(saq => saq.availableQty).forEach(saq => storesAvailableQte += saq);
        }
        return storesAvailableQte;
    }

    getCollectionsLocalStorage(): Collection[] {
        const collections: Collection[] = [];
        const NowDate = new Date();
        collections.push({
            startDate: new Date(NowDate.getFullYear(), 9),
            endDate: new Date(NowDate.getFullYear(), 3),
            saison: 'H' + (NowDate.getFullYear() - 2000)

        });
        collections.push({
            startDate: new Date(NowDate.getFullYear(), 4),
            endDate: new Date(NowDate.getFullYear(), 8),
            saison: 'E' + (NowDate.getFullYear() - 2000)

        });
        return collections;
    }

    getSavedCategories(): Category[] {
        if (localStorage.getItem(this.globals.localStorageCategories) != undefined) {
            return JSON.parse(localStorage.getItem(this.globals.localStorageCategories));
        }
        return null;
    }


    getArticleListDimensionByCodeType(articles: Article[], codeType: string): Dimension[] {
        const dims: Dimension[] = [];
        (articles && articles.length > 0) ? articles.map(p => dims.push(p.dimensions.find(p => p.codeType == codeType))) : [];
        return dims.filter((thing, index, self) => self.findIndex((t) => {
            return t.code === thing.code && t.codeFamily === thing.codeFamily && t.codeType === thing.codeType;
        }) === index);
    }

    findArticleByColor(articles: Article[], color: Dimension): Article {

        return (articles) ? articles.find(a =>
            this.getArticleDimensionLibelleByCodeType(a, 'DI2') == color.libelle) : null;
    }

    getArticleDimensionLibelleByCodeType(article: Article, codeType: string): string {
        if (article != undefined && article.dimensions != undefined) {
            const dim: Dimension = article.dimensions.find(p => p.codeType == codeType);
            return (dim != undefined) ? dim.libelle : '';
        } else {
            return '';
        }
    }


    getPromotionClassTag(article: Article): string {
        return (article && article.promotion != undefined && article.promotion.remise > 0) ? 'flag-promo-' + article.promotion.remise : '';
    }
    getPromotionTag(article: Article): string {
        return (article && article.promotion != undefined && article.promotion.remise > 0) ? 'promo-' + article.promotion.remise : '';
    }
    getPromotion(article: Article): number {
        return (article && article.promotion != undefined && article.promotion.remise > 0) ?
            article.promotion.remise : 0;
    }
    isInt(n: number): boolean {
        return Number(n) === n && n % 1 === 0;
    }
    GetArticlesByCodeBarres(codes: string[]): Observable<Article[]> {
        return this.http
            .post<Article[]>(WS_URL.article.getarticlesbycodearresURL, codes);

    }

    getRecommendedArticles(customerCode: string): Observable<Article[]> {
        let params: HttpParams = new HttpParams();
        params = params.set('customerCode', customerCode);
        return this.http
            .get<Article[]>(WS_URL.article.recommendedArticleURL, { params });
    }
}
