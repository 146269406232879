import { Component, OnInit, AfterViewInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { HomeSection } from 'src/app/shared-module/models/e-shop';
import { Category } from 'src/app/shared-module/models/articles';
import { FormGroup, FormControl } from '@angular/forms';
import { SharedArticleService } from 'src/app/shared-module/services/shared.article.service';
import { AdminService } from 'src/app/shared-module/services/admin.service';
import { FileStreamManager } from 'src/app/shared-module/services/file-stream-manager';
import { LoaderService } from 'src/app/shared-module/services/loader.service';
import { SharedCategoryService } from 'src/app/shared-module/services/shared.category.service';

@Component({
    selector: 'admin-update-popup',
    templateUrl: './admin.update.component.html'
    // ,
    // styleUrls: ['./Scripts/app/modules/eshop/views-style/home.css']
})
export class AdminUpdateComponent implements OnInit, AfterViewInit {

    @ViewChild('fileInput') fileInput: any;
    @Input()  currentsection: HomeSection;
    @Output()  close: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output()  responseHomeSection: EventEmitter<HomeSection> = new EventEmitter<HomeSection>();
    fileName = '';
     categories: Category[];
    updateForm: FormGroup;
      imageChanged = false;
      updatedHomeSection: HomeSection;
      isChekedOffer = true;


    constructor(public  articleService: SharedArticleService,
                public  categoryService: SharedCategoryService,
                public  adminService: AdminService,
                public  fileStreamManager: FileStreamManager, public  loaderService: LoaderService) { }



    ngOnInit(): any {
        this.initCategories();
        if (this.currentsection == undefined || this.currentsection == null) {
            this.updatedHomeSection = new HomeSection();
            this.updatedHomeSection.id = -1;
        }
        this.updatedHomeSection = new HomeSection();
        this.updatedHomeSection.id = this.currentsection.id;
        this.updatedHomeSection.idCategorie = this.currentsection.idCategorie;
        this.updatedHomeSection.imageUrl = this.currentsection.imageUrl;
        this.updatedHomeSection.sectionName = this.currentsection.sectionName;
        this.updatedHomeSection.subtitle = this.currentsection.subtitle;
        this.updatedHomeSection.title = this.currentsection.title;
        this.updatedHomeSection.subtitleImage = this.currentsection.subtitleImage;
        this.updatedHomeSection.isVisible = this.currentsection.isVisible;
        this.isChekedOffer = this.currentsection.isVisible;

        this.updateForm = new FormGroup({
            title: new FormControl(this.updatedHomeSection.title),
            subtitle: new FormControl(this.updatedHomeSection.subtitle),
            subtitleImage: new FormControl(this.updatedHomeSection.subtitleImage),
            isVisibleSection: new FormControl(this.isChekedOffer),
        });

    }

    initCategories() {
        this.categories = [];
        this.categories = this.categoryService.getSavedCategories();
        if (this.categories == undefined) {
            this.categoryService.getCategories().subscribe(Categories => {
                this.categories = Categories;
            });
        }
    }

    ngAfterViewInit(): void {
        this.initCategories();
    }

    public  getCategories(rootCat: Category, cat: Category = null): Category[] {
        if (this.categories != undefined && this.categories.length > 0) {
            if (rootCat == null) {
                let catPerm: Category;
                catPerm = this.categories[1];
                this.categories[1] = this.categories[2];
                this.categories[2] = catPerm;
                return this.categories;
            } else {
                if (cat == null) {
                    return this.categories.find(p => p.code == rootCat.code).children;
                } else {
                    return this.categories.find(p => p.code == rootCat.code)
                        .children.find(p => p.code == cat.code).children;
                }
            }
        }
        return [];
    }

    public  updateImage(event: any) {

        this.imageChanged = true;
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader();
            reader.onload = (event: any) => {
                this.updatedHomeSection.imageUrl = event.target.result;
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    public  closePopup() {
        this.close.emit(true);
    }

    onSubmit($event: any) {
        if (this.updateForm.valid == true) {

            const titleForm = this.updateForm.get('title');
            const subtitleForm = this.updateForm.get('subtitle');
            const subtitleImageForm = this.updateForm.get('subtitleImage');
            const homeSection: HomeSection = new HomeSection();
            homeSection.idCategorie = this.updatedHomeSection.idCategorie;
            homeSection.sectionName = '';
            if (titleForm != null) {
                homeSection.title = titleForm.value;
            }
            if (subtitleForm != null) {
                homeSection.subtitle = subtitleForm.value;
            }
            if (subtitleImageForm != null) {
                homeSection.subtitleImage = subtitleImageForm.value;
            }
            homeSection.id = this.updatedHomeSection.id;
            homeSection.imageUrl = '';
            homeSection.sectionName = this.updatedHomeSection.sectionName;
            homeSection.isVisible = this.isChekedOffer;
            
            this.create(homeSection);

        } else {
            console.warn('form errors', this.updateForm);
        }

    }

    public  setCategorie(categorie: Category) {
        this.updatedHomeSection.idCategorie = categorie.id;
    }


    create(homeSection: HomeSection): void {
        this.loaderService.display(true);
        this.adminService
            .update(homeSection)
            .subscribe(res => {
                this.loaderService.display(false);
                if (res != null) {
                    this.uploadFile(res);
                } else {
                    this.close.emit(true);
                }
            }, (error) => {
                this.close.emit(true);
                this.loaderService.display(false);
            });
    }

    uploadFile(homeSection: HomeSection) {
        const fi = this.fileInput.nativeElement;
        if (fi.files && fi.files[0]) {
            const fileToUpload = fi.files[0];
            const fileType = fi.files[0].type.slice(fi.files[0].type.indexOf('/') + 1);
            if (fi.files && fi.files[0]) {
                this.fileName = (fi.files[0].name != undefined) ? fi.files[0].name : '';
                this.loaderService.display(true);
                this.fileStreamManager
                    .upload(fileToUpload, fileType, homeSection.id)
                    .subscribe(res => {

                        this.close.emit(true);
                        this.loaderService.display(false);
                        this.responseHomeSection.emit(res);
                        this.fileInput.nativeElement.value = '';
                    }, (error) => {
                        this.loaderService.display(false);
                        this.close.emit(true);
                    });
            }
        } else {
            this.close.emit(true);
            this.loaderService.display(false);
            this.responseHomeSection.emit(homeSection);
        }
    }
    isChekedOfferFunc() {
        this.isChekedOffer = !this.isChekedOffer;
    }
}


