import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';




@Injectable({
    providedIn: 'root'
})
export class GlobalsService {


    public windowsScreenWidth: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public windowsScreenHeight: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    public storeId = '200';
    public sotreMarsaId = '300';
    public storeIdManzah5 = '400';
    public storeIdWeb = '999';
    public etablissementId = '999';
    public depoId = '200';
    public localStorageCategories = 'categories';
    public localStorageCollections = 'collections';
    public discountID = 'SITWEB';
    public titles = {
        ASSOCIEZ_AVEC: 'ET SI VOUS L\'ASSOCIEZ AVEC',
        RECOMMANDE_POUR_VOUS: 'SÉLECTIONNÉS POUR VOUS',
        AIMEREZ_PEUT_ETRE: 'Produits similaires'
    };

    // localHosthttps://www.smt-sps.com.tn/clicktopay/Classique/pay.aspx
    public clientId = '43b95a1e-2ab9-4cc8-8bdb-ab83a60838e0';
    // public clientId: string = "9090e0cc-e1b5-468d-ac2d-e86992d27f8b"; // pb_tsw
    public apiKeyWsAIRecommandation = '+YXap7DaYWnKBWzmiGA4on7Wzb7aEsdTZB5HrX207+3570M/3hMYUSyPOPn3KnTyV66TzrjzU7ImlQd3qFOudA==';
    public apiKeyWsAIAssociation = '1kNOnsByfzB1AEM/z+0DE6X0rU4jgPmF2ICqrnHmrIcvjlwcQb6SVp59hstruVcrQhmw+GnQkG3sHJ1RS1Orlw==';
    public UrlImage = 'http://197.13.7.99:5005/upload_image/';
    public image_url = 'http://197.13.5.148:80/Images/';
    // public image_url = "./Images/";
    // SC00009
    //// localHost
    // public clientId: string = "56298d6a-bfc5-4ac0-ad7b-277c58f45ddb";
    // http://devitco-tenant.azurewebsites.net
    // public clientId: string = "4966f55c-a5e2-4bc4-9ec3-69e953925de4" ;
    //  "https://clictopay.monetiquetunisie.com/clicktopay/"
    public SPS_ACTION_URL = 'https://www.smt-sps.com.tn/clicktopay/getpurchase.aspx';
    public SPS_ACTION_TEST_URL = 'https://clictopay.monetiquetunisie.com/ClickToPay/getpurchase.aspx';
    public SPS_Action_UNTRUSTED_TEST_URL = 'https://test.clictopay.com/rest/register.do';

    public merchantKit = {
        id: '1482056458',
        action: 'PAYMENT ',
        currency: 'TND',
        spsTerminal: '1482056458',
        spsAffiliate: '1480458775',
        spsKey: 'p74e854+t2365t',
        language: 'FR',
    };

    public newGuid() {
        return 'xxx-xx-4x-yxx-xx'.replace(/[xy]/g, function(c) {
            let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    public StringIsNullOrEmpty(myString: string): boolean {

        const res = (myString == null || myString == undefined || myString.length <= 0);
        return res;

    }

}
