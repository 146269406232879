import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/shared-module/services/loader.service';
import { SearchService } from 'src/app/shared-module/services/search.service';
import { Article } from 'src/app/shared-module/models/articles';
import { GlobalsService } from 'src/app/shared-module/services/globals.service';
import { Router } from '@angular/router';
import { SharedArticleService } from 'src/app/shared-module/services/shared.article.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
})
export class SearchComponent implements OnInit {

  @Output()
  public close: EventEmitter<boolean> = new EventEmitter<boolean>();

  public searchHttpSubscription: Subscription;
  public isLoading = false;
  minLength = 3;
  articleFamilyName: string;

  articles: Article[] = [];
  filtredaArticles: Article[] = [];

  constructor(private loaderService: LoaderService,
              public searchService: SearchService,
              public globals: GlobalsService,
              public router: Router,
              public articleService: SharedArticleService,

  ) { }

  ngOnInit() {
  }



  closePopup() {
    this.close.emit(true);
  }


  onKeyep(textToSerch: string) {
    if (this.searchHttpSubscription != null) {
      this.searchHttpSubscription.unsubscribe();
    }
    this.loaderService.display(false);
    this.isLoading = false;
    this.startSearching(textToSerch);
  }
  navigateToDetails(article: Article): any[] {
    return this.articleService.getCategoriesUrlRouter(article);
  }

  getImageLinkFromArticle(article: Article): string {

    // let basePrimaryUrl: string = 'https://medias.petit-bateau.com/Media/catalog/';
    // let sizePrimaryUrl: string = '118x118/';
    // return basePrimaryUrl + sizePrimaryUrl + article.imgReference + '_PRI' + '.jpg';;
    const basePrimaryUrl: string = this.globals.image_url;
    const sizePrimaryUrl = 'small/';
    return basePrimaryUrl + sizePrimaryUrl + article.imgReference + '.jpg';

  }
  getAllProduct() {
    this.searchService.artcicles = this.articles;
    this.router.navigate(['/article/list', this.articleFamilyName]);
    this.closePopup();
  }

  startSearching(textToSerch: string) {

    if (this.searchHttpSubscription != null) {
      this.searchHttpSubscription.unsubscribe();
    }
    textToSerch = textToSerch.trim();
    if (this.isLoading == false && textToSerch.length > this.minLength) {
      this.articleFamilyName = textToSerch;
      this.isLoading = true;

      this.loaderService.display(true);
      this.searchHttpSubscription = this.searchService.searchArticlesByLibelle(textToSerch, -1)
        .subscribe(
          (res) => {
            this.isLoading = false;
            this.loaderService.display(false);

            this.initArticles(res);
          },
          (error) => {
            this.isLoading = false;
            this.loaderService.display(false);
            this.articles = [];
          });
    } else {
      this.articles = [];
      this.filtredaArticles = [];
    }
  }
  initArticles(res) {
    this.articles = res;
    if (this.articles != null && this.articles.length > 0) {
      this.filtredaArticles = this.articles.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t.codeFamily === thing.codeFamily
        ))
      );
    }
  }

}
