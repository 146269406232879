
import { Basket } from './basket';
import { Article } from '../articles';

export class Commande {
    id: number;
    article: Article;
    discountTypeId: string;
    externalReference: string;
    itemCode: string;
    itemId: string;
    itemReference: string;
    label: string;
    movementReasonId: string;
    origin: string;
    salesPersonId: string;
    taxExcludedNetUnitPrice: number;
    taxExcludedUnitPrice: number;
    taxIncludedNetUnitPrice: number;
    taxIncludedUnitPrice: number;
    quantity: number;
    valide = true;
    availableQtyInStore: number;
    error: any = {
        code: 200,
        message : 'aucune erreur',
    };
    basket: Basket;
    basket_Id: number;
    article_Id: number;
}
