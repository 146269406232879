import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { WS_URL } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private AUTH_HEADER = 'Authorization';
    private token = 'secrettoken';
    private refreshTokenInProgress = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private authService: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


        req = this.addAuthenticationToken(req);

        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error && error.status === 401) {
                    // 401 errors are most likely going to be because we have an expired token that we need to refresh.
                    // if (this.refreshTokenInProgress) {
                    //     // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
                    //     // which means the new token is ready and we can retry the request again
                    //     return this.refreshTokenSubject.pipe(
                    //         filter(result => result !== null),
                    //         take(1),
                    //         switchMap(() => next.handle(this.addAuthenticationToken(req)))
                    //     );
                    // } else {
                    this.refreshTokenInProgress = true;

                    // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
                    this.refreshTokenSubject.next(null);

                    return this.refreshAccessToken().pipe(
                        switchMap((success) => {
                            this.refreshTokenSubject.next(success);
                            return next.handle(this.addAuthenticationToken(req));
                        }),
                        // When the call to refreshToken completes we reset the refreshTokenInProgress to false
                        // for the next time the token needs to be refreshed
                        finalize(() => this.refreshTokenInProgress = false)
                    );
                    // }
                } else {
                    return throwError(error);
                }
            })
        );
    }

    private refreshAccessToken(): Observable<any> {
        return this.authService.getAppCredentials();
    }

    private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {

        this.token = this.authService.getToken();
        // If we do not have a token yet then we should not set the header.
        // Here we could first retrieve the token from where we store it.
        if (!this.token) {
            return request;
        }
        // If you are calling an outside domain then do not add the token.
        // if (!request.url.match(/www.mydomain.com\//)) {
        //     return request;
        // }
        return request.clone({
            headers: request.headers.set(this.AUTH_HEADER, 'Bearer ' + this.token)
        });
    }

}




import { timer, throwError } from 'rxjs';
import { mergeMap, retryWhen, catchError, filter, take, switchMap, finalize } from 'rxjs/operators';

export interface RetryParams {
    maxAttempts?: number;
    scalingDuration?: number;
    shouldRetry?: ({ status: number }) => boolean;
}

const defaultParams: RetryParams = {
    maxAttempts: 3,
    scalingDuration: 1000,
    shouldRetry: ({ status }) => status >= 400
};

export const genericRetryStrategy = (params: RetryParams = {}) => (attempts: Observable<any>) => attempts.pipe(
    mergeMap((error, i) => {
        const { maxAttempts, scalingDuration, shouldRetry } = { ...defaultParams, ...params };
        const retryAttempt = i + 1;
        // if maximum number of retries have been met
        // or response is a status code we don't wish to retry, throw error
        if (retryAttempt > maxAttempts || !shouldRetry(error)) {
            return throwError(error);
        }
        // retry after 1s, 2s, etc...
        return timer(retryAttempt * scalingDuration);
    })
);
