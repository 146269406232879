import { Component, OnInit } from '@angular/core';
import { Category, Article } from 'src/app/shared-module/models/articles';
import { HomeSection } from 'src/app/shared-module/models/e-shop';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/shared-module/services/admin.service';
import { GlobalsService } from 'src/app/shared-module/services/globals.service';
import { Customer } from 'src/app/shared-module/models/account';
import { SharedCustomerService } from 'src/app/shared-module/services/shared.customer.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {


    constructor(public router: Router,
                public customerService: SharedCustomerService,
                public adminService: AdminService,
                public globals: GlobalsService, ) { }
    customer: Customer = new Customer();
    isUser = true;
    modifiedSection: string;
    adminUpdatePopupIsVisible = false;
    currentsection: HomeSection;
    homeSections: HomeSection[];
    articles: Article[];
    getslideid: number;

    ngOnInit(): any {

        this.customer = this.customerService.getCustomer();
        this.modifiedSection = 'middle';
        this.adminService.get().subscribe(
            res => {
                this.homeSections = res;
            });
        this.articles = [];

    }

    public getnavigateToArticlesfromCategories(section: HomeSection) {
        let cats: Category[] = [];

        this.adminService.getHomeSectionsCategories(section)
            .subscribe(res => {
                cats = res;
                if (cats != undefined && cats != null && cats.length > 2) {

                    this.router.navigate(['/article/list', [cats[2].id, cats[1].id, cats[0].id]]);
                } else {
                    this.router.navigate(['/article/list', [cats[1].id, cats[0].id]]);
                }
            }, (error) => {
            });
    }

    closeAdminUpdatePopup(event) {
        this.adminUpdatePopupIsVisible = false;
    }

    openPopup(section: HomeSection) {
        if (section == null) {
            this.currentsection = new HomeSection();
            this.currentsection.imageUrl = 'PB_LOGO.jpg';
            this.currentsection.id = -1;
            
        } else {
            this.currentsection = section;
        }

        this.adminUpdatePopupIsVisible = true;
    }
    getSection(sectionName: number): HomeSection {
        let res = new HomeSection();
        if (this.homeSections != undefined && this.homeSections.length > 0) {
            res = this.homeSections.find(x => x.sectionName == '' + sectionName);
        }
        return (res != undefined) ? res : new HomeSection();
    }

    updateSection(responseHomeSection: HomeSection) {
        const itemIndex = this.homeSections.findIndex(x => x.id == responseHomeSection.id);
        this.homeSections[itemIndex] = responseHomeSection;
    }



}
