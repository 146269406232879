import { Commande } from './commande';
import { User } from '../account';
export class Basket {

    constructor() {
        this.commandes = [];
    }
    id: number;
    date: Date;
    user: User;
    user_Id: string;
    stump: string;
    number: number;
    type: string;
    internalReference: string;
    commandes: Commande[];
}
