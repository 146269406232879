export class Category {
    public id: number;
    public code: string;
    public libelle: string;
    public codeParent: string;
    public isSelected: boolean;
    public children: Category[];
    public Description: string;
    public level: number;
    public rank: number;
    constructor() { }
}
