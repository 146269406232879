import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WS_URL } from 'src/environments/environment';
import { GlobalsService } from './globals.service';
import { Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';




@Injectable()
export class AuthService {


    constructor(private http: HttpClient,
                private globals: GlobalsService) {

    }

    getAppCredentials(): Observable<Token> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
        return this.http
            .post<Token>(WS_URL.auth.token,
                'grant_type=client_credentials&client_id=' + this.globals.clientId,
                {
                    headers
                }).pipe(
                    map(res => {
                        const token = res;
                        if (localStorage.getItem('access_token') != undefined) {
                            localStorage.setItem('access_token', token.access_token);
                        } else {
                            localStorage.setItem('audiance_token', token.access_token);
                        }
                        return res;
                    })
                );
    }



    getToken(): string {
        const audiance_token = localStorage.getItem('audiance_token');
        const access_token = localStorage.getItem('access_token');
        let token = (access_token) ? access_token : audiance_token;
        token = (token == undefined || token == null) ? localStorage.getItem('access_token') : token;
        return token;
    }

    logout() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('audiance_token');
    }

}


export class Token {

    access_token: string;
    token_type: string;
    expires_in: number;
    refresh_token: string;
    audiance: string;
    issued: string;
    expires: string;

}
