import { Injectable } from '@angular/core';
import { Commande, Basket } from '../models/shopping-cart';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient, HttpRequest, HttpInterceptor, HttpEvent, HttpHandler } from '@angular/common/http';
import { WS_URL } from 'src/environments/environment';
import { SharedArticleService } from './shared.article.service';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from './google-analytics.service';

@Injectable({
    providedIn: 'root'
})
export class SharedShoppingCartService {
    basketUpdated = false;

    showPopupBasket = false;
    popupOpened = false;
    CurrentBasket: BehaviorSubject<Basket> = new BehaviorSubject<Basket>(new Basket());
    removeCommandePopupStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);



    constructor(
        public http: HttpClient,
        public articleService: SharedArticleService,
        public router: Router,
        private googleAnalyticsService: GoogleAnalyticsService) {
        this.getLocalStorageCurrentBasket();
    }



    getLocalStorageComandes(): Commande[] {
        const basket = this.CurrentBasket.value;
        return (basket != undefined) ? ((basket.commandes != undefined) ? basket.commandes : []) : [];
    }


    getLocalStorageCurrentBasket(): Basket {

        let basket: Basket;
        if (localStorage.getItem('currentBasket') != undefined) {
            basket = JSON.parse(localStorage.getItem('currentBasket'));

            this.CurrentBasket.next(basket);

        }
        return basket;
    }

    addCommande(commande: Commande, userId: string): Observable<Commande> {
        return this.http
            .post<Commande>(WS_URL.basket.addCommandeUrl + '?userId=' + userId, commande)
            .pipe(map((res) => {
                return res;
            }));


    }

    addLocalStorageCommande(commande: Commande, maxQte: number = -1): Commande {
        this.googleAnalyticsService.addToCartEmitter(commande.article.libelle, commande.quantity);

        let basket: Basket = this.CurrentBasket.value;
        commande.valide = true;
        let cmdsList: Commande[] = (basket != undefined && basket.commandes != undefined) ? basket.commandes : [];
        if (this.localStorageIsDateOverTaken() == true) {
            localStorage.removeItem('currentBasket');
        }
        if (cmdsList != null && cmdsList != undefined && cmdsList.length >= 0) {
            const commandeTemp = cmdsList.find(x => x.article.codeBarre === commande.article.codeBarre);

            if (commandeTemp != undefined) {
                if (maxQte > -1) {
                    commande.quantity = (commande.quantity + commandeTemp.quantity <= maxQte)
                        ? commande.quantity + commandeTemp.quantity : maxQte;
                    commande.id = (commandeTemp.id != null && commandeTemp.id >= 0) ? commandeTemp.id : commande.id;
                }

                cmdsList.splice(cmdsList.findIndex(cmd => cmd.article.codeBarre == commandeTemp.article.codeBarre), 1);
            }
        } else {
            cmdsList = [];
        }
        cmdsList.push(commande);
        localStorage.removeItem('currentBasket');
        if (basket == undefined) {
            basket = new Basket();
            basket.commandes = [];
        }
        basket.commandes = cmdsList;
        localStorage.setItem('currentBasket', JSON.stringify(basket));
        this.CurrentBasket.next(basket);

        return commande;
    }

    localStorageIsDateOverTaken(): boolean {

        const dateString = localStorage.getItem('expiration-date');
        if (dateString != null && dateString != undefined) {
            const date: Date = new Date(dateString);
            if (new Date() > date.addDays(7)) {
                return true;
            }
        }
        return false;
    }

    removeCommandesFromCurrentBasket() {
        localStorage.removeItem('currentBasket');
        this.CurrentBasket.next(null);
    }

    checkForQuantity(): Observable<Commande[]> {
        const commandes = this.getLocalStorageComandes();
        if (commandes != undefined && commandes.length > 0) {
            return this.articleService.getArticleStoreAvailableQty(commandes.map(cmd => cmd.article.codeBarre))
                .pipe(map((res) => {

                    if (res != undefined && res.length > 0) {
                        commandes.forEach(cmd => {
                            const storeAvailableQte = this.articleService.getStoresAvailableQuantity(res, cmd.article);

                            if (storeAvailableQte != undefined && storeAvailableQte >= cmd.quantity) {
                                cmd.error = {
                                    code: 200,
                                    message: 'aucune erreur',
                                };

                            } else {
                                if (storeAvailableQte == undefined || storeAvailableQte == 0) {
                                    cmd.error = {
                                        code: 404,
                                        message: 'Cet article n\'est plus disponible en stock.'
                                    };


                                } else if (storeAvailableQte != undefined && storeAvailableQte < cmd.quantity) {
                                    cmd.error = {
                                        code: 500,
                                        message: 'Il ne reste plus que ' + storeAvailableQte + ' pièce(s) de cet article dans le stock.'
                                            + 'Vous souhaitez en commander ' + cmd.quantity
                                    };
                                }


                                cmd.quantity = storeAvailableQte;
                                this.updatelocalStorageCommande(cmd);
                            }

                        });
                    }
                    this.showPopupBasket = (new Date() > (this.getLocalStorageExpirationDate().addDays(2))) ? true : false;
                    if (this.showPopupBasket != true) {
                        const cmds = this.getLocalStorageComandes();

                        this.showPopupBasket = (cmds != undefined && cmds.find(cmd => cmd.error != undefined && cmd.error.code != 200) != undefined) ? true : false;
                    }

                    return this.getLocalStorageComandes();
                }, error => {
                    return this.getLocalStorageComandes();
                }));



        } else {
            const prom: Observable<Commande[]> =
                of(this.getLocalStorageComandes());

            return prom;
        }
    }

    updatelocalStorageCommande(cmd: Commande) {
        const cmds = this.getLocalStorageComandes();
        if (cmds != undefined) {
            const commande = cmds.find(cmd => cmd.article.codeBarre == cmd.article.codeBarre);
            if (commande != undefined) {
                this.removeLocalStorageCommande(commande);
            }
            if (cmd.quantity > 0) {
                this.addLocalStorageCommande(cmd);
            }
        }
    }

    removeLocalStorageCommande(cmd: Commande) {
        let cmdsList: Commande[] = [];
        const basket: Basket = this.CurrentBasket.value;

        if (basket != undefined) {
            cmdsList = basket.commandes;
            const index = cmdsList.findIndex(p => p.article.codeBarre == cmd.article.codeBarre);
            cmdsList.splice(index, 1);
            localStorage.removeItem('currentBasket');
            basket.commandes = cmdsList;
            localStorage.setItem('currentBasket', JSON.stringify(basket));
            if (cmdsList.length == 0) {
                localStorage.removeItem('expiration-date');
            }
        }
        this.CurrentBasket.next(basket);
    }

    getLocalStorageExpirationDate(): Date {
        const dateString = localStorage.getItem('expiration-date');
        let date = new Date();
        if (dateString == undefined || dateString == null) {
            date = new Date().addDays(7);
            localStorage.setItem('expiration-date', (new Date().addDays(7)).toString());
        } else {
            date = new Date(dateString);
        }
        return date;
    }
    removeLocalStorageCommandeList() {
        if (this.router.url == '/basket') {
            this.popupOpened = true;
        }
        localStorage.removeItem('currentBasket');
        localStorage.removeItem('expiration-date');
        this.CurrentBasket.next(null);
    }

    updateShoppingCart(commandes: Commande[]) {
        const self = this;
        const basket: Basket = this.CurrentBasket.value;
        basket.commandes = commandes;
        this.updateLocalStorageCurrentBasket(basket);
    }

    validateBasket(Stump: string): Observable<boolean> {
        return this.http
            .get<boolean>(WS_URL.basket.validateBasketUrl + '?Stump=' + Stump);

    }
    getCurrentBasket(idUser: string): Observable<Basket> {
        return this.http
            .get<Basket>(WS_URL.basket.getCurrentBasketURL + '?idUser=' + idUser)
            .pipe(map(res => {
                this.updateLocalStorageCurrentBasket(res);
                return res;
            }));
    }




    updateLocalStorageCurrentBasket(basket: Basket): Basket {
        if (basket != null) {
            if (localStorage.getItem('currentBasket') != undefined) {
                localStorage.removeItem('currentBasket');
            }
            localStorage.setItem('currentBasket', JSON.stringify(basket));



        }
        this.CurrentBasket.next(basket);
        return basket;
    }


    removeCommande(commande: Commande): Observable<boolean> {
        return this.http
            .post<boolean>(WS_URL.basket.removeCommandeUrl, commande);
    }
    updateBasket(basket: Basket): Observable<Basket> {
        return this.http.post<Basket>(WS_URL.basket.updateBasketUrl, basket);

    }

    updateCommande(commande: Commande): Observable<boolean> {

        return this.http.post<boolean>(WS_URL.basket.updateCommandeUrl, commande);
    }


    removeCommandes(idBasket: number): Observable<boolean> {
        return this.http
            .get<boolean>(WS_URL.basket.removeCommandesUrl + '?idBasket=' + idBasket);
    }
}

declare global {
    interface Date {
        addDays(days: number): Date;
    }
}
Date.prototype.addDays = function (days: number) {
    const date = new Date();
    return new Date(date.getTime() + (1000 * 60 * 60 * 24 * days));
};











