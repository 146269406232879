export class Store {
    id: number;
    code: string;
    libelle: string;
    adresse1: string;
    adresse2: string;
    adresse3: string;
    codePostal: string;
    ville: string;
    pays: string;
    telephone: string;
    typeEtab: string;
    ferme: string;
    surSite: string;
    heureDebMatin: Date;
    heureDebAprem: Date;
    heureFinMatin: Date;
    heureFinAprem: Date;
}
