import { Component, HostListener, OnInit, ViewChild, AfterViewInit, AfterViewChecked, ElementRef } from '@angular/core';
import { GlobalsService } from './shared-module/services/globals.service';
import { LoaderService } from './shared-module/services/loader.service';
import { HeaderComponent } from './eshop-module/header/header.component';
import { SharedCustomerService } from './shared-module/services/shared.customer.service';
import { SharedShoppingCartService } from './shared-module/services/shared.shopping.cart.service';
import { SharedAccountService } from './shared-module/services/account/shared.account.service';
import { SharedArticleService } from './shared-module/services/shared.article.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { SalesDocument } from './shared-module/models/payment/sales_document';
import { SalesDocumentService } from './shared-module/services/sales_document.service';
import { Article } from './shared-module/models/articles';
import { WishListService } from './shared-module/services/whish-list.service';
import { WS_URL } from 'src/environments/environment';

declare let gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit, AfterViewChecked {

    constructor(public globalsService: GlobalsService,
        public loaderService: LoaderService,
        public accountService: SharedAccountService,
        public shoppingCartService: SharedShoppingCartService,
        public customerService: SharedCustomerService,
        public articleService: SharedArticleService,
        public router: Router,
        public route: ActivatedRoute,
        public elementRef: ElementRef,
        public salesDocumentService: SalesDocumentService,
        public wishListService: WishListService) {

        console.log('Bien venu chez Petit-bateau tunisie');
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                // console.log ('navigationEnds', event.urlAfterRedirects);
                gtag('config', 'UA-149800561-1',
                    {
                        'page_path': event.urlAfterRedirects
                    }
                );
            }
        });
    }

    title = 'petitBateauTn';
    JQuery: any;
    viewIsAnimating = false;
    isConnectedUser: boolean;
    lastPurchase: SalesDocument;
    public recommendedArticle: Article[];

    showLoader: boolean;
    menuIsOpenPhoneDevice: boolean;
    viewChecked: boolean;

    @ViewChild(HeaderComponent)
    headerComponent: HeaderComponent;
    ngAfterViewChecked(): void {
        this.viewChecked = true;
    }
    ngOnInit(): void {
        this.loaderService.status.subscribe(status => this.showLoader = status);
        this.onResize(null);


        this.accountService.isConnectedUser.subscribe((val: boolean) => {
            this.isConnectedUser = val;
            if (val == true) {
                this.loaderService.display(true);
                this.shoppingCartService.getCurrentBasket(this.accountService.getUserId())
                    .subscribe(res => {
                        if (res != undefined && res != null) {
                            this.loaderService.display(true);
                            let basket = res;
                            this.shoppingCartService.updateBasket(basket)
                                .subscribe(res => {
                                    basket.commandes.forEach(x => {
                                        this.shoppingCartService.removeLocalStorageCommande(x);
                                    });
                                    basket = res;

                                    if (basket != null && basket.commandes != null && basket.commandes.length > 0) {
                                        basket.commandes.forEach(x => this.shoppingCartService.addLocalStorageCommande(x));
                                    }
                                    this.shoppingCartService.checkForQuantity().subscribe(res => {
                                        this.loaderService.display(false);
                                    }, error => {
                                        this.loaderService.display(false);
                                    });
                                }, error => { this.loaderService.display(false); });
                        }
                        this.loaderService.display(false);

                    }, res => {
                        this.loaderService.display(false);
                    });


                if (this.customerService.getCustomer() == undefined) {
                    // getting user after Login
                    this.loaderService.display(true);
                    this.customerService.getCustomerByEmail(this.accountService.getConnectedUsername())
                        .subscribe(res => {
                            this.loaderService.display(false);
                            // getting Last Perchase
                            this.getLastPurchaseInit();
                            let wishList: Article[] = [];
                            wishList = this.wishListService.getWishListItemsLocal();
                            if (wishList == undefined || wishList.length <= 0) {
                                this.wishListService.getAll(this.accountService.getConnectedUsername())
                                    .subscribe((res) => {
                                        wishList = res.articles;
                                        this.wishListService.addWishListElementsLocal(wishList);
                                    });
                            }
                            WS_URL.account.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
                            this.router.navigate([WS_URL.account.returnUrl]);
                        }, error => {
                            this.loaderService.display(false);
                        });


                } else {
                    const customer = this.customerService.getCustomer();
                    if (customer != null) {
                        this.articleService.getRecommendedArticles(customer.customerId)
                            .subscribe(res => {
                                this.recommendedArticle = res;
                            });
                    }
                    this.getLastPurchaseInit();
                }
            } else {
                this.wishListService.removeAllWishListLocal();
            }
        });


    }


    getLastPurchase(salesDocuments: SalesDocument[]): SalesDocument {
        const a1: Date = Math.max.apply(null, salesDocuments.map(function (e) {
            return e.date;
        }));
        const lastPurchaseArray: SalesDocument[] = salesDocuments.filter(x =>
            new Date(x.date).toDateString() == new Date(a1).toDateString() &&
            x.number == Math.max(x.number)
        );
        if (lastPurchaseArray != undefined && lastPurchaseArray.length > 0) {
            return lastPurchaseArray[0];
        }
        return undefined;
    }

    getLastPurchaseInit() {
        let salesDocuments: SalesDocument[] = [];
        const salesDocument = new SalesDocument();
        const customer = this.customerService.getCustomer();
        if (customer != undefined && customer != null) {
            salesDocument.customerId = customer.customerId;
            salesDocument.storeId = this.globalsService.storeIdWeb;
            this.salesDocumentService.getHeaderList(salesDocument)
                .subscribe(res => {
                    if (res !== undefined) {
                        salesDocuments = res;
                        if (salesDocuments != undefined && salesDocuments.length > 0) {
                            this.lastPurchase = this.getLastPurchase(salesDocuments);
                        }
                    }
                });

        }


    }


    ngAfterViewInit(): void {
        this.onResize(null);
    }


    @HostListener('window:resize', ['$event'])
    onResize(event) {


        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        if (this.globalsService.windowsScreenWidth.value == 0
            || Math.abs(this.globalsService.windowsScreenWidth.value - screenWidth) > 20) {
            this.globalsService.windowsScreenWidth.next(screenWidth);
        }
        if (this.globalsService.windowsScreenHeight.value == 0
            || Math.abs(this.globalsService.windowsScreenHeight.value - screenHeight) > 20) {
            this.globalsService.windowsScreenHeight.next(screenHeight);
        }

    }

    toggleOpenPhoneDevice(menuOpenStatus: boolean) {
        this.menuIsOpenPhoneDevice = menuOpenStatus;
    }



}
