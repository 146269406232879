import { Address } from './Address';
import { Payment } from './Payment';
import { Commande } from '../shopping-cart';
import { ShippingTaxe } from './ShippingTaxe';

export class SalesDocument {

    public customerId: string;
    public active: boolean;
    public currencyId: string;
    public date: Date;
    public internalReference: string;
    public externalReference: string;
    public linesUnmodifiable: boolean;
    public billingStatus: string;
    public deliveryStatus: string;
    public deliveryStoreId: string;
    public deliveryType: string;
    public followUpStatus: string;
    public paymentStatus: string;
    public returnStatus: string;
    public shippingStatus: string;
    public origin: string;
    public storeId: string;
    public type: string;
    public reference: string;
    public label: string;
    public netUnitPrice: number;
    public number: number;
    public stump: string;
    public reasonId: string;
    public taxExcludedTotalAmount: number;
    public taxIncludedTotalAmount: number;
    public payments: Array<Payment>;
    public commandes: Array<Commande>;
    public shippingtaxes: Array<ShippingTaxe>;
    public address: Address;
}
