import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { WS_URL } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Article } from '../models/articles';
import { map } from 'rxjs/operators';
import { GoogleAnalyticsService } from './google-analytics.service';


@Injectable()
export class SearchService {

  public artcicles: Article[];


  constructor(public http: HttpClient,
    private googleAnalyticsService: GoogleAnalyticsService) { }


  searchArticlesByLibelle(search: string, size: any = -1, step: any = -1): Observable<any> {
    let params = new HttpParams();
    params = params.append('search', search);
    params = params.append('size', size);
    params = params.append('step', step);

    return this.http
      .get(WS_URL.search.articles, { params })
      .pipe(map((res) => {
        this.googleAnalyticsService.searchEmitter(search);
        return res;
      }));

  }
  searchGetArticlesByLibelle(search: string, size: any = -1, step: any = -1): Observable<any> {
    let params = new HttpParams();
    params = params.append('size', size);
    params = params.append('step', step);

    return this.http
      .get(WS_URL.search.articleGet, { params });

  }

}
