import { of, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { SliderItem } from '../../models/core/SliderItem';


@Injectable()
export class conseilValiseSliderService {
    public getSliderItems(): Observable<SliderItem[]> {
        const list: SliderItem[] = [{
            id: 0,
            imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dw06a2911e/images/Pages_contenu/2018/valise-maternite/des/contenu-de-ma-valise-3.jpg',
           SliderSection_Id: 0
        }, {
            id: 1,
                imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dw2fa2eabe/images/Pages_contenu/2018/valise-maternite/des/contenu-de-ma-valise-1.jpg',
            SliderSection_Id: 0
        }, {
            id: 2,
                imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dwb344dcfe/images/Pages_contenu/2018/valise-maternite/des/contenu-de-ma-valise-2.jpg',
            SliderSection_Id: 0
        }];
        return of(list);
    }


}
