import { Component, OnInit } from '@angular/core';
import { routerNgProbeToken } from '@angular/router/src/router_module';
import { Router } from '@angular/router';

@Component({
  selector: 'app-advertising-banner',
  templateUrl: './advertising-banner.component.html'
})
export class AdvertisingBannerComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit() {
  }
  navigateToArticle() {
    this.router.navigate(['/article/list/allpromotion']);
  }

}
