import { NgModule } from '@angular/core';
import { LoaderComponent } from './loader/loader.component';
import { CapitalizeFirstPipe } from './pipes/capitalize-first.pipe';
import { SharedRoutingModule } from './shared.routing.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { SearchService } from './services/search.service';
import { AdminService } from './services/admin.service';
import { SharedCustomerService } from './services/shared.customer.service';
import { MenuService } from './menu.service';
import { AuthService } from './services/auth.service';
import { FileStreamManager } from './services/file-stream-manager';
import { AuthGuard } from './guards/auth.guard';
import { CustomerGuard } from './guards/customer.guard';
import { WishListService } from './services/whish-list.service';
import { CountryService } from './services/country.service';
import { DimensionService } from './services/dimension.service';
import { PingService } from './services/ping.service';
import { LesIconiquesSiderService } from './services/LesIconiquesSiderService/les-iconiques-slider.service';
import { MarqueSecondLifeSliderService } from './services/MarqueSecondLifeSliderService/marque-second-life-slider.service';
import { MarqueYoutubeVideoSliderService } from './services/marqueYoutubeService/marque-youtube-video-slider.service';
import { MatieresFinitionsSliderSrvice } from './services/MatieresFinitionsSliderSrvice/matières-finitions-slider.service';
import { ServiceGarantiesSiderService } from './services/ServiceGarantiesSiderService/services.garanties.slider.service';
import { ContactService } from './services/contact.service';
import { InformationService } from './services/information.service';
import { HistorySliderService } from './services/history-slider.service';
import { SalesDocumentService } from './services/sales_document.service';
import { DeliveryService } from './services/delivery.service';
import { LoyaultyService } from './services/loyaulty.service';
import { conseilValiseSliderService } from './services/conseilValiseSliderService/conseil.valise.slider.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';



@NgModule({
  declarations: [LoaderComponent, CapitalizeFirstPipe],
  imports: [
    SharedRoutingModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

  ],
  providers: [

    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    SearchService,
    AdminService,
    SharedCustomerService,
    MenuService,
    AuthService,
    FileStreamManager,
    AuthGuard,
    CustomerGuard,
    WishListService,
    CountryService,
    DimensionService,
    PingService,
    LesIconiquesSiderService,
    MarqueSecondLifeSliderService,
    MarqueYoutubeVideoSliderService,
    MatieresFinitionsSliderSrvice,
    ServiceGarantiesSiderService,
    ContactService,
    InformationService,
    HistorySliderService,
    conseilValiseSliderService,
    SalesDocumentService,
    DeliveryService,
    LoyaultyService, 
    GoogleAnalyticsService
  ],

  exports: [LoaderComponent,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CapitalizeFirstPipe,
  ]
})
export class SharedModuleModule { }
