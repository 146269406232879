import { Injectable } from '@angular/core';
import { HomeSection } from '../models/e-shop';
import { Observable } from 'rxjs';
import { WS_URL } from 'src/environments/environment';
import { Category } from '../models/articles';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AdminService {

    constructor(private http: HttpClient) {

    }

    public isAdmin(): boolean {
        try {
            if (localStorage.getItem('userRoles') != undefined) {
                let userRoles: string[] = JSON.parse(localStorage.getItem('userRoles'));
                if (userRoles.filter(x => x == 'Admin') != undefined && userRoles.filter(x => x == 'Admin').length > 0) {
                    return true;
                }

            }

        } catch (error) {

        }
        return false;
    }


    public getHomeSectionsCategories(homeSection: HomeSection): Observable<Category[]> {
        return this.http
            .post<Category[]>(WS_URL.homeSection.getHomeSectionCategories, homeSection);

    }
    public get(): Observable<HomeSection[]> {
        return this.http
            .get<HomeSection[]> (WS_URL.homeSection.get);
    }


    public update(homeSection: HomeSection): Observable<HomeSection> {
        return this.http
            .post<HomeSection>(WS_URL.homeSection.update, homeSection);
    }


}
