import { Customer } from '../models/account';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SharedAccountService } from './account/shared.account.service';
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpResult } from '../models/core/http-result';
import { WS_URL } from 'src/environments/environment';

@Injectable()
export class SharedCustomerService {
    public localcustomer: BehaviorSubject<Customer> = new BehaviorSubject<Customer>(null);
    constructor(public http: HttpClient,
                public accountService: SharedAccountService) { }


    getCustomer(): Customer {
        let customer =  null ;
        if (localStorage.getItem('customer')) {
            const item = localStorage.getItem('customer');
            customer = JSON.parse(item) as Customer;
        }
        return customer;
        // return ((customer) ? customer : this.getWalkAwayCustomer());
    }


    create(customer: Customer): Observable<Customer> {
        return this.http
            .post<Customer>(WS_URL.customer. addUrl, customer);
    }


    getCustomerByEmail(Email: string): Observable<Customer> {
        let params = new HttpParams();
        params =  params.set('email', Email);
        return this.http
            .get<Customer>(WS_URL.customer.getByEmailUrl, { params })
            .pipe(map((res) => {
                this.addCustomerLocalStorage(res);
                this.localcustomer.next(res);
                return res;
            }));

    }




    getCustomerIdByReference(customerReference: string): Observable<HttpResult> {

        let params = new HttpParams();
        params =   params.set('customerRef', customerReference);

        return this.http
            .get<HttpResult>(WS_URL.customer.getcustomerIdByReferenceUrl, { params });
    }


    search(customer: Customer): Observable<Customer[]> {
        return this.http
            .post<Customer[]>(WS_URL.customer.searchCustomerIdsUrl, customer);
    }


    getDetails(CustomerId: string): Observable<Customer> {
        let params = new HttpParams();
        params =   params.set('customerId', CustomerId);
        return this.http.get<Customer>(WS_URL.customer.getDetailsUrl, { params });
    }

    update(CustomerId: string, customer: Customer): Observable<HttpResult> {
        let params = new HttpParams();
        params =  params.set('CustomerId', CustomerId);
        return this.http
            .put<HttpResult>(WS_URL.customer.updateUrl, customer, { params });
    }



     updateCustomerLocalStorage(email: string = null): Observable<Customer> {
        const customerEmail = (email != null) ? email : this.accountService.getConnectedUsername();
        return this.getCustomerByEmail(customerEmail);
    }

     addCustomerLocalStorage(customer: Customer) {

        if (customer != undefined) {
            if (customer.titleId != null && customer.titleId == '001') {
                customer.titleId = 'Mr';
            } else if (customer.titleId != null && customer.titleId == '003') {
                customer.titleId = 'Mme';
            } else if (customer.titleId != null && customer.titleId == '005') {
                customer.titleId = 'Mlle';
            }
            localStorage.setItem('customer', JSON.stringify(customer));
        }

    }


    getWalkAwayCustomer(): Customer {
     const customer = new Customer();
     customer.customerId = 'SC000004';
     return customer ;
    }




}
