import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SliderItem } from '../../models/core/SliderItem';

@Injectable()
export class LesIconiquesSiderService {
    public getSliderItems(): Observable<SliderItem[]> {
        const list: SliderItem[] = [{
            id: 0,
            imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dwf0acc2ba/images/Pages_contenu/2018/Marque/VISU_FR/ICONIQUE_cire.jpg',
            title: 'Le Ciré',
            description: 'Empreint d’histoire, le ciré sent bon la Bretagne et les embruns.Mais il a depuis longtemps quitté le pont des navires pour rejoindre la terre ferme.Pièce maîtresse dans l’armoire des enfants qui aiment sauter dans les flaques, il est aussi plébiscité par les grands qui se protègent des averses avec style. Il pleut ? Et alors !',
            SliderSection_Id: 0
        }, {
            id: 1,
                imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dwc21fdac2/images/Pages_contenu/2018/Marque/VISU_FR/ICONIQUE_caban.jpg',
                title: 'Le caban',
                description: 'L’esprit officier de marine offre au caban Petit Bateau une coupe unique et travaillée, reconnaissable entre mille ! Considéré comme le manteau incontournable, le caban Petit Bateau vogue depuis des années entre les générations et les tendances.',
            SliderSection_Id: 0
        }, {
            id: 2,
                imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dw6dcecca2/images/Pages_contenu/2018/Marque/VISU_FR/ICONIQUE_mariniere.jpg',
                title: 'La marinière',
                description: 'Chez Petit Bateau, on sait y faire avec la marinière ! Vêtement iconique, à la fois confortable et résistant, ses rayures sont revisitées à chaque saison. Indémodable, elle est ancrée dans les vestiaires des petits comme des grands.',
            SliderSection_Id: 0
        }, {
            id: 3,
                imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dwb4c0ca87/images/Pages_contenu/2018/Marque/VISU_FR/ICONIQUE_pull_marin.jpg',
                title: 'Le pull marin',
                description: 'Avec ses boutons dorés, le pull marin s’offre un côté chic.Doux et chaud, c’est un essentiel du dressing des petits moussaillons tout autant que des marins d’eau douce.',
            SliderSection_Id: 0
        }, {
            id: 4,
                imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dw62958919/images/Pages_contenu/2018/Marque/VISU_FR/ICONIQUE_tshirt.jpg',
                title: 'Le t-shirt',
                description: 'Le t-shirt Petit Bateau était à l’origine un sous- vêtement en laine, puis ensuite en coton de première qualité.En 1994, un grand couturier décide de mettre sous ses tailleurs de simples t - shirts blancs pour son défilé : des t - shirts Petit Bateau! Une petite révolution est lancée.Le dessous prend le dessus et devient un vêtement',
            SliderSection_Id: 0
        }, {
            id: 5,
                imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dw7868caf1/images/Pages_contenu/2018/Marque/VISU_FR/ICONIQUE_culotte.jpg',
                title: 'La culotte',
                description: 'En 1918, Etienne Valton prit une décision tranchante : couper les jambes du traditionnel caleçon long pour obtenir une petite culotte bien plus pratique et confortable. Une invention unique qui révolutionna à jamais l’histoire des sous- vêtements.',
            SliderSection_Id: 0
        }, {
            id: 6,
                imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dw9b743d54/images/Pages_contenu/2018/Marque/VISU_FR/ICONIQUES_body.jpg',
                title: 'Le Body',
                description: 'En 1980, Petit Bateau améliore le bien-être des parents et des bébés en imaginant un body avec des pressions à l’entrejambes et des emmanchures américaines.Liberté de mouvement, facilité pour l’habillage et le change, ces qualités font du body le vêtement indispensable dans l’armoire de bébé.Encore fallait- il l’inventer.',
            SliderSection_Id: 0
        }, {
            id: 7,
                imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dwc6a68b42/images/Pages_contenu/2018/Marque/VISU_FR/ICONIQUE_doudou.jpg',
                title: 'Le doudou',
                description: 'Longues oreilles et coton tout doux, les doudous Petit Bateau câlinent des générations d’enfants.Entièrement doublés en coton très doux à l’extérieur et en ouatine moelleuse à l’intérieur, c’est le premier compagnon des tout petits. Doudou lapin, ourson ou souris, doudou plat et surtout doudou personnalisable... choisissez le premier doudou de bébé. Une parfaite idée pour un cadeau de naissance.',
            SliderSection_Id: 0
        }];
        return of(list);
    }


}
