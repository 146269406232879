import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WS_URL } from 'src/environments/environment';
import { Observable } from 'rxjs';


@Injectable()
export class LoyaultyService {


    constructor(private http: HttpClient) {

    }

    getCustomerAvailableLoyaltyPoints(customerReference: string): Observable<any> {
        let params: HttpParams = new HttpParams();
        params = params.append('customerReference', customerReference);
        return this.http
            .get<any>(WS_URL.loyaulty.loyaultyEndPoint, { params });
    }
}
