import { Component, OnInit, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GlobalsService } from 'src/app/shared-module/services/globals.service';

declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  newsLetterFormGroup: FormGroup;
  private JQuery: any;
  private isOpened = false;
  showCommande = false;
  showServices = false;
  showConseils = false;
  showPetitbateau = false;
  lastNameState = true;
  firstNameState = true;
  newsLetterFirstName = '';
  newsLetterLastName = '';
  newsLetterEmail = '';
  showNewsLetterPopUp = false;


  constructor(private elementRef: ElementRef,
              public globalsService: GlobalsService) { }


  ngOnInit(): void {
    this.newsLetterFormGroup = new FormGroup({
      email: new FormControl(null, Validators.required),
      firstname: new FormControl(null, Validators.required),
      lastname: new FormControl(null, Validators.required)
    });
  }


  ngAfterViewInit(): void {
    const self = this;
    self.JQuery = $(this.elementRef.nativeElement);
    self.JQuery.find('#choose-language').click(function() {
      self.JQuery.find('#list-language').slideToggle(function() {
        self.isOpened = !self.isOpened;
      });
    });
  }
  openCommande() {

    this.showCommande = !(this.showCommande);
  }
  openServices() {

    this.showServices = !(this.showServices);
  }
  openConseils() {

    this.showConseils = !(this.showConseils);
  }
  openPetitbateau() {

    this.showPetitbateau = !(this.showPetitbateau);
  }

  openNewsLetterPopup() {
    this.showNewsLetterPopUp = true;
  }
  closeNewsLetterPopUp() {
    this.newsLetterFirstName = '';
    this.newsLetterLastName = '';
    this.firstNameState = true;
    this.lastNameState = true;
    this.showNewsLetterPopUp = false;
  }

  onSubmit($event: any) {
    $event.preventDefault();
    this.closeNewsLetterPopUp();


  }
}
