import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { GlobalsService } from 'src/app/shared-module/services/globals.service';
import { CategoryMenuComponent } from '../category-menu/category-menu.component';
import { SharedAccountService } from 'src/app/shared-module/services/account/shared.account.service';
import { SharedCustomerService } from 'src/app/shared-module/services/shared.customer.service';
import { Router } from '@angular/router';
import { Customer } from 'src/app/shared-module/models/account';
import { MenuService } from 'src/app/shared-module/menu.service';
import { SharedArticleService } from 'src/app/shared-module/services/shared.article.service';
import { SharedShoppingCartService } from 'src/app/shared-module/services/shared.shopping.cart.service';


declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'

})
export class HeaderComponent implements OnInit {


  private JQuery: any;

  @ViewChild(CategoryMenuComponent)
  categoryMenu: CategoryMenuComponent;

  @Output()
  menuOpenPhoneDevice: EventEmitter<boolean> = new EventEmitter();

  screenWidth: number;
  searchingArticle: boolean;
  menuIsOpenPhoneDevice: boolean;
  categoriesIsSelected: boolean;
  viewIsAnimating = false;
  menuIsOpen: boolean;
  customer: Customer;
  selecteCountry = false;


  constructor(
    public elementRef: ElementRef,
    public router: Router,
    public globalService: GlobalsService,
    public sharedAccountService: SharedAccountService,
    public sharedCustomerService: SharedCustomerService,
    public menuService: MenuService,
    public sharedArticleService: SharedArticleService,
    public shoppingCartService: SharedShoppingCartService) { }

  ngOnInit() {
    this.JQuery = $(this.elementRef.nativeElement);
    this.globalService.windowsScreenWidth.subscribe(x => this.screenWidth = x);
    // this.sharedShoppingCartService.CurrentBasket.subscribe(Basket => {

    //    this.sum = this.sharedArticleService.getSum(this.sharedArticleService.getCmdArticles(Basket.commandes));
    // });

    // this.customer = this.sharedCustomerService.getCustomer();

    this.sharedCustomerService.localcustomer.subscribe(value => {
      if (value != null) {
        this.customer = value;
      } else {
        this.customer = this.sharedCustomerService.getCustomer();
      }
    }

    );


  }

  closeMenus() {
    this.menuService.closeMenus([this.categoryMenu]);
  }

  goToAccount(val: number = -1) {
    if (val == 0) {
      this.sharedAccountService.display(true);
    } else {
      this.sharedAccountService.display(false);
    }
    this.router.navigate(['account']);

  }

  gotomyaccount() {

    if (this.sharedAccountService.isConnectedUser.value == true && this.customer != null && this.customer != undefined) {
      this.router.navigate(['/customers/information-personel']);
    } else {
      this.router.navigate(['/account']);
    }

  }


  toggleMenuPhoneDevice() {
    this.menuIsOpenPhoneDevice = !this.menuIsOpenPhoneDevice;
    this.menuOpenPhoneDevice.emit(this.menuIsOpenPhoneDevice);
    this.closeOpenMenuBacgroundPhone(this.menuIsOpenPhoneDevice);
  }


  closeOpenMenuBacgroundPhone(closeMenu: boolean) {
    const self = this;

    const layerMenuElement = self.JQuery.find('#layer-menu');
    layerMenuElement.removeClass('fadeout');
    layerMenuElement.removeClass('fadein');
    if (closeMenu == true) {
      layerMenuElement.removeClass('display-none');
      layerMenuElement.addClass('display-block');
    } else {
      layerMenuElement.addClass('display-none');
      layerMenuElement.removeClass('display-block');
    }
  }

  setHeightNavigation(): any {
    if (this.globalService.windowsScreenWidth.value < 989) {
      return this.globalService.windowsScreenHeight.value + 'px';
    } else {
      return 'initial';
    }
  }


  initJqueryCategoryLoadFinished($event) {
    const self = this;
    if ($event == true) {
      self.JQuery.find('.category-container').hover(function() {
        self.closeHeader();
      });
    } else {
      self.JQuery.find('.category-container').hover(function() { });
    }

  }

  closeHeader(isConnected: boolean = true) {
    if (isConnected) {
      const self = this;

      if (self.JQuery.find('.menu-account').hasClass('open')) {
        self.JQuery.find('#layer-account').addClass('display-block');
        self.JQuery.find('#layer-account').animate({
          height: '0',
          padding: '0'
        }, 400, function() {
          self.JQuery.find('#layer-account').removeClass('display-block');
          self.viewIsAnimating = false;
        });
        self.JQuery.find('.menu-account').removeClass('open');
      }
    }
  }

  close(close: boolean) {
    if (this.globalService.windowsScreenWidth.value > 989) {
      this.closeOpenMenuBacground(close);
    } else {

      this.closeOpenMenuBacgroundPhone(close);
      this.menuIsOpenPhoneDevice = !this.menuIsOpenPhoneDevice;
      this.menuOpenPhoneDevice.emit(close);

    }

  }

  closeOpenMenuBacground(closeMenu: boolean) {
    const self = this;

    const layerMenuElement = self.JQuery.find('#layer-menu');
    if (closeMenu == true) {
      layerMenuElement.removeClass('display-none');
      layerMenuElement.addClass('display-block');
      layerMenuElement.addClass('fadein');
      layerMenuElement.removeClass('fadeout');

    } else {


      layerMenuElement.removeClass('fadein');
      layerMenuElement.addClass('fadeout');
    }

    self.menuIsOpen = closeMenu;
  }

  categoriesIsSelectedEvent(event) {
    this.categoriesIsSelected = event;
  }

  logout() {
    this.sharedAccountService.LogOut();
    this.sharedCustomerService.localcustomer.next(null);
    this.shoppingCartService.CurrentBasket.next(null);

  }

}
