export class User {
    Id: string;
    Email: string;
    Password: string;
    ConfirmPassword: string;
    UserName: string;
    CustomerId: string;
    isAdmin = false;
    roles: string[] = [];
}

export class Login {
    Email: string;
    Password: string;
    RemeberMe: boolean;
}
