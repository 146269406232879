import { HttpClient } from '@angular/common/http';
import { Country } from '../models/core/country';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WS_URL } from 'src/environments/environment';


@Injectable()
export class CountryService {


    constructor(private http: HttpClient) { }

    getCountries(): Observable<Country[]> {
        return this.http.get<Country[]>(WS_URL.country.getAll);
    }

}
