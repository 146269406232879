import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { HomeComponent } from './eshop-module/home/home.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '*', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () => import('./eshop-module/eshop-module.module').then(m => m.EshopModuleModule)
  },
  {
    path: 'article',
    loadChildren: () => import('./article-module/article-module.module').then(m => m.ArticleModuleModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./account-module/account-module.module').then(m => m.AccountModuleModule)
  },
  {
    path: 'shopping-card',
    loadChildren: () => import('./shopping-card-module/shopping-card-module.module').then(x => x.ShoppingCardModuleModule)
  },
  {
    path: 'customers',
    loadChildren: () => import('./customers-module/customers-module.module').then(x => x.CustomersModule)
  },
{
  path: 'information',
  loadChildren: () => import('./informations-module/informations-module.module').then(x => x.InformationsModuleModule)

}


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
