export class Customer {
    public titleId: string;
    public addressLine1: string;
    public addressLine2: string;
    public addressLine3: string;
    public brandName: string;
    public city: string;
    public countryId: string;
    public country: string;
    public countryIdType: string;
    public nata: Boolean;
    public regionId: string;
    public zipCode: string;
    public alternateFirstName: string;
    public alternateLastName: string;
    public alternateEmail: string;
    public email: string;
    public emailingAccepted: Boolean;
    public sendReceiptByMail: Boolean;
    public firstName: string;
    public isCompany: Boolean;
    public lastName: string;
    public alternatePhoneNumber: string;
    public cellularPhoneNumber: string;
    public homePhoneNumber?: string;
    public officePhoneNumber: string;
    public salesPerson2Id: string;
    public salesPerson3Id: string;
    public salesPersonId: string;
    public userDefinedTable0Value: string;
    public userDefinedTable1Value: string;
    public userDefinedTable2Value: string;
    public userDefinedTable3Value: string;
    public userDefinedTable4Value: string;
    public userDefinedTable5Value: string;
    public userDefinedTable6Value: string;
    public userDefinedTable7Value: string;
    public userDefinedTable8Value: string;
    public userDefinedTable9Value: string;
    public usualStoreId: string;
    public activityCode: string;
    public barCode: string;
    public birthDateDay: number;
    public birthDateMonth: number;
    public birthDateYear: number;
    public companyIdNumber: string;
    public companyTitleId: string;
    public currencyId: string;
    public fiscallId: string;
    public italianFiscallId: string;
    public languageId: string;
    public longDescription: string;
    public nationalityId: string;
    public passportNumber: string;
    public sex: string;
    public shortName: string;
    public booleanValue: Boolean;
    public dateValue: Date;
    public id: number;
    public enumId: string;
    public listElementValue: string;
    public numberValue: number;
    public textValue: string;
    public valueType: string;
    public vATSystem: string;
    public validAlternativeEmail: Boolean;
    public validEmail: Boolean;
    public validHomePhone: Boolean;
    public validMobile: Boolean;
    public optinAlternativeEmail: string;
    public optinEmail: string;
    public optinHomePhone: string;
    public optinMobile: string;
    public optinOfficePhone: string;
    public optinPostal: string;
    public userDefinedBooleanValue: Boolean;
    public userDefineDateValue: Date;
    public userDefinedTextValue: string;
    public userDefineValueValue: number;
    public customerId: string;
    public InternetPassword: string;
    // User Info
    Email: string;
    Password: string;
    confirmPassword: string;
    isAdmin = false;
}
