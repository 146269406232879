
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { SliderItem } from '../models/core/SliderItem';


@Injectable()
export class HistorySliderService {


    public getSliderItems(): Observable<SliderItem[]> {
        const list: SliderItem[] = [{
            id: 0,
            imageUrl: 'informations/HISTOIRE_1893.jpg',
            SliderSection_Id: 0
        }, {
            id: 1,
            imageUrl: 'informations/HISTOIRE_1912.jpg',
            SliderSection_Id: 0
        }, {
            id: 2,
            imageUrl: 'informations/HISTOIRE_1918.jpg',
            SliderSection_Id: 0
        }, {
            id: 3,
            imageUrl: 'informations/HISTOIRE_1920.jpg',
            SliderSection_Id: 0
        }, {
            id: 4,
            imageUrl: 'informations/HISTOIRE_1937.jpg',
            SliderSection_Id: 0
        }, {
            id: 5,
            imageUrl: 'informations/HISTOIRE_1950.jpg',
            SliderSection_Id: 0
        }, {
            id: 6,
            imageUrl: 'informations/HISTOIRE_1960.jpg',
            SliderSection_Id: 0
        }, {
            id: 7,
            imageUrl: 'informations/HISTOIRE_1970.jpg',
            SliderSection_Id: 0
        }, {
            id: 8,
            imageUrl: 'informations/HISTOIRE_1980.jpg',
            SliderSection_Id: 0
        }, {
            id: 9,
            imageUrl: 'informations/HISTOIRE_1990.jpg',
            SliderSection_Id: 0
        }, {
            id: 10,
            imageUrl: 'informations/HISTOIRE_2000.jpg',
            SliderSection_Id: 0
        }];
        return of( list);
    }





}
