

export class HomeSection {

    id: number;
    title = '';
    subtitle = '';
    imageUrl = '';
    idCategorie = 0;
    sectionName = '';
    subtitleImage = '';
    isVisible: boolean;

}
