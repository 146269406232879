import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GlobalsService } from './services/globals.service';

@Injectable()
export class MenuService {

    public menuSelected: BehaviorSubject<Array<MenuSelected>> ;

    constructor(private globalService: GlobalsService) {
        this.menuSelected = new BehaviorSubject([
            {
                imageNotSelect: 'assets/Content/img/picto/3d',
                imageIsSelect: 'assets/Content/img/picto/3d-blue',
                isSelected: false,
                name: 'commandes',
                href: '/customers/Mes-commandes',
                title: 'Mes commandes et retours'
            },
            {
                imageIsSelect: 'assets/Content/img/picto/fidélité-blue',
                imageNotSelect: 'assets/Content/img/picto/fidélité',
                isSelected: false,
                name: 'fidelity-page',
                href: '/customers/fidelity-page',
                title: 'Mon programme de fidélité',
            },
            {
                imageIsSelect: 'assets/Content/img/picto/list-envi-blue',
                isSelected: false,
                name: ' wish-list',
                imageNotSelect: 'assets/Content/img/picto/list-envi',
                href: '/customers/Ma-wishList',
                title: 'Ma liste d’envie',
            },
            {
                imageIsSelect: 'assets/Content/img/picto/info-blue',
                imageNotSelect: 'assets/Content/img/picto/info',
                isSelected: false,
                name: 'Mesinformation',
                href: '/customers/Mesinformation',
                title: 'Mes informations',
            },
            {
                imageIsSelect: 'assets/Content/img/picto/maps-blue',
                imageNotSelect: 'assets/Content/img/picto/maps',
                isSelected: false,
                name: 'Mesadresse',
                href: '/customers/Mesadresse',
                title: 'Mes adresses',
            },

        ]);
    }

    toggleSelect(a: MenuSelected): any {
        this.menuSelected.value.forEach(x => x.isSelected = false);
        this.menuSelected.value.find(x => x.href == a.href).isSelected = true;
    }

    selectMenuByHref(href: string): any {
        if (this.menuSelected.value.find(x => x.href == href) != undefined && this.menuSelected.value.find(x => x.href == href) != null) {
            this.menuSelected.value.forEach(x => x.isSelected = false);
            this.menuSelected.value.find(x => x.href == href).isSelected = true;
        }
    }

    closeMenus(menus: any[]): void {
        if (this.globalService.windowsScreenWidth.value > 989) {
          menus.forEach(menu => menu.closeMenu());
        }
      }



}
export class MenuSelected {

    public imageNotSelect: string;
    public imageIsSelect: string;
    public isSelected: boolean;
    public name: string;
    public href: string;
    public title: string;

}
