import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Category } from 'src/app/shared-module/models/articles';
import { LoaderService } from 'src/app/shared-module/services/loader.service';
import { SharedCategoryService } from 'src/app/shared-module/services/shared.category.service';
import { SharedArticleService } from 'src/app/shared-module/services/shared.article.service';

@Component({
  selector: 'app-admin-category',
  templateUrl: './admin-category.component.html'
})
export class AdminCategoryComponent implements OnInit {
 @Output() public close: EventEmitter<boolean> = new EventEmitter<boolean>();
@Input() public categories: Category[];
  selectedCategory: Category;
  categoryId: any;
  parentId: any;
  firstParentId: any;
  result: string;
  constructor(public articleService: SharedArticleService,
              public categoryService: SharedCategoryService,
              public loaderService: LoaderService) { }

  ngOnInit(): void {
    this.categoryService.categoriesHasChanged.subscribe();
    if (this.categories == undefined || this.categories == null || this.categories.length == 0) {
      this.categories = this.articleService.getSavedCategories();
    }
    this.categoryService.categoryUpdatePopupIsVisible.subscribe();
    this.categories = this.categoryService.getSortedCategories(this.categories, true);
    this.initRank();

  }

  initRank() {
    let fIndex = 0;
    this.categories.forEach(x => {
      x.rank = fIndex;
      fIndex += 1;
      if (x.children != undefined && x.children.length > 0) {

        let xIndex = 0;
        x.children.forEach(c => {
          c.rank = xIndex;
          xIndex += 1;
          if (c.children != undefined && c.children.length > 0) {
            let cIndex = 0;
            c.children.forEach(xc => {
              xc.rank = cIndex;
              cIndex += 1;
            });
          }
        });
      }
    });
  }

  selectCategory(categoryId: any, parentId: any = null, firstParentId: any = null) {
    this.selectedCategory = null;
    this.categoryId = categoryId;
    this.parentId = parentId;
    this.firstParentId = firstParentId;
    this.selectedCategory = this.categories.find(x => x.id == firstParentId)
      .children.find(x => x.id == parentId)
      .children.find(x => x.id == categoryId);

  }


  goUp() {
    const categoryId = this.categoryId;
    const parentId = this.parentId;
    const firstParentId = this.firstParentId;
    if (firstParentId != null) {
      this.selectedCategory = this.categories.find(x => x.id == firstParentId)
        .children.find(x => x.id == parentId)
        .children.find(x => x.id == categoryId);
      if (this.selectedCategory.rank > 0) {

        this.categories.forEach(x => {

          if (x.id == firstParentId) {
            x.children.forEach(y => {
              if (y.id == parentId) {
                y.children.forEach(c => {
                  if (c.rank == this.selectedCategory.rank - 1 && c.id != this.selectedCategory.id) {
                    c.rank = c.rank + 1;

                  }
                  if (c.id == this.selectedCategory.id) {
                    c.rank = c.rank - 1;

                  }
                });
              }
            });
          }
        });
      }

    }
  }

  goDown() {
    const categoryId = this.categoryId;
    const parentId = this.parentId;
    const firstParentId = this.firstParentId;
    if (firstParentId != null) {
      const maxRank = Math.max.apply(Math, this.categories.find(x => x.id == firstParentId)
        .children.find(x => x.id == parentId)
        .children.map(o => {
          return o.rank;
        }));
      this.selectedCategory = this.categories.find(x => x.id == firstParentId)
        .children.find(x => x.id == parentId)
        .children.find(x => x.id == categoryId);
      if (this.selectedCategory.rank < maxRank + 1) {
        this.categories.forEach(x => {

          if (x.id == firstParentId) {
            x.children.forEach(y => {
              if (y.id == parentId) {
                y.children.forEach(c => {
                  if (c.rank == this.selectedCategory.rank && c.id != this.selectedCategory.id) {
                    c.rank = c.rank - 1;
                  }
                  if (c.id == this.selectedCategory.id && this.selectedCategory.rank < maxRank) {
                    c.rank = c.rank + 1;
                  }
                });
              }
            });
          }
        });
      }

    }

  }


  getSortedCategories(categories: Category[] | undefined, sortChilddren: boolean = false): Category[] {
    if (categories != undefined && categories != null && categories.length > 0) {

      categories = categories.sort((a1, a2) => {
        return (a1.rank > a2.rank) ? 1 : (a1.rank < a2.rank) ? -1 : (a1.libelle > a2.libelle) ? 1 : (a1.libelle < a2.libelle) ? -1 : 0;
      });
      if (sortChilddren == true) {
        categories.forEach(x => { x.children = this.categoryService.getSortedCategories(x.children, true); });
      }
    }
    return categories;
  }

  closePopup() {
    this.close.emit(true);
  }

  updateCategories() {
    this.loaderService.display(true);
    this.categoryService.updateCategories(this.categories)
      .subscribe(res => {
        this.loaderService.display(false);
        this.categoryService.categoriesHasChanged.next(res);
        if (res != null && res == true) {
          this.result = 'Modification avec success';
        } else {
          this.result = 'Erreur de modification';
        }
      }, (error) => {
        this.result = 'Erreur de modification';
        this.loaderService.display(false);
      });
  }

}
