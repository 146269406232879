import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';




@Injectable()
export class PingService {

    constructor(private http: HttpClient) { }

    public ping(url: string): Promise<string> {

        return this.http
            .get(url)
            .toPromise()
            .then(res => {
                return url;
            })
            .catch(res => {
                return null;
            });
    }

}
