export const informationData   =    [
    {
      year: 1893,
      urlmedia: 'https://medias.petit-bateau.com/Media/img/landing/marque/1893-img.jpg',
      title: 1893,
      content: 'Ouverture de l\'usine de bonneterie par Pierre Valton à Troyes, près de Paris en France.'
    },
    {
      year: 1912,
      urlmedia: 'https://medias.petit-bateau.com/Media/img/landing/marque/1912-img.jpg',
      title: 1912,
      content: 'Création des sous-vêtements en coton blanc lavable remplaçant la laine inconfortable et peu hygiénique.'
    },
    {
      year: 1918,
      urlmedia: 'https://medias.petit-bateau.com/Media/img/landing/marque/1918-img.jpg',
      title: 1918,
      content: 'Invention de la petite culotte par Etienne Valton qui en deux coups de ciseaux légendaires, supprime les jambes des caleçons longs !'
    },
    {
      year: 1920,
      urlmedia: 'https://medias.petit-bateau.com/Media/img/landing/marque/1920-img.jpg',
      title: 1920,
      content: 'Création de la marque Petit Bateau et apparition de Marinette comme égérie publicitaire.'
    },
    {
      year: 1937,
      urlmedia: 'https://medias.petit-bateau.com/Media/img/landing/marque/1937-img.jpg',
      title: 1937,
      content: 'Remise du Grand prix de l\'exposition universelle de Paris pour l\'invention de la petite culotte.'
    },
    {
      year: 1950,
      urlmedia: 'https://medias.petit-bateau.com/Media/img/landing/marque/1950-img.jpg',
      title: 1950,
      content: 'Invention de l\'emmanchure américaine, qui permet d\'enfiler son body en passant facilement la tête et les bras de bébé.'
    },
    {
      year: 1960,
      urlmedia: 'https://medias.petit-bateau.com/Media/img/landing/marque/1960-img.jpg',
      title: 1960,
      content: 'Invention de la bouclette éponge et de la bouclette velours pour des pyjamas toujours plus doux.'
    },
    {
      year: 1970,
      urlmedia: 'https://medias.petit-bateau.com/Media/img/landing/marque/1970-img.jpg',
      title: 1970,
      content: 'Invention du Milleraies, tricotage inédit créant une multitude de fines rayures bicolores.'
    },
    {
      year: 1980,
      urlmedia: 'https://medias.petit-bateau.com/Media/img/landing/marque/1980-img.jpg',
      title: 1980,
      content: 'Création du body naissance, le premier body avec des pressions à l\'entrejambe.'
    },
    {
      year: 1990,
      urlmedia: 'https://medias.petit-bateau.com/Media/img/landing/marque/1990-img.jpg',
      title: 1990,
      content: 'Invention de la laine et coton, une matière unique qui associe la douceur du coton sur la peau à la chaleur de la laine.'
    },
    {
      year: 2000,
      urlmedia: 'https://medias.petit-bateau.com/Media/img/landing/marque/2000-img.jpg',
      title: 2000,
      content: 'Ouverture d\'une boutique sur la plus belle avenue du monde : les Champs-Elysées à Paris.'
    }
  ];
