import { Component, OnInit, ElementRef, Output, EventEmitter } from '@angular/core';
import { GlobalsService } from 'src/app/shared-module/services/globals.service';
import { Category } from 'src/app/shared-module/models/articles';
import { SharedCategoryService } from 'src/app/shared-module/services/shared.category.service';
import { AdminService } from 'src/app/shared-module/services/admin.service';



declare var $: any;


@Component({
  selector: 'app-category-menu',
  templateUrl: './category-menu.component.html'
})
export class CategoryMenuComponent implements OnInit {

   JQuery: any;
  phoneBeginSelection: boolean;
  secondLevelCategoriesIsSelected: boolean;
  menuisOpen: boolean;
  screenWidth: number;

  categoriesselected: Category;
  categories: Category[];
  selectedCategories: Category[];

  @Output()
  close: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  categoriesIsSelected: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  loadFinished: EventEmitter<boolean> = new EventEmitter<boolean>();

  categoriesUpdate: Category[] = [];
  constructor(public elementRef: ElementRef,
              public globalService: GlobalsService,
              public sharedCategoryService: SharedCategoryService,
              public adminService: AdminService) { }

  ngOnInit() {
    this.onResize();
    this.reload();
    this.menuisOpen = false;
    this.categories = [];
    this.categories = this.sharedCategoryService.getSavedCategories();
    this.sharedCategoryService.categoriesHasChanged.subscribe();
    this.close.emit(false);
  }

  onResize() {
    this.globalService.windowsScreenWidth.subscribe(screenWidth => {
      this.screenWidth = screenWidth;
      if (this.categories != undefined && this.categories.length > 0) {
        this.unselectAll();
        if (screenWidth < 989) {
          const self = this;
          self.JQuery = $(this.elementRef.nativeElement);
          self.JQuery.find('.sous-nav').removeClass('closed');
          this.close.emit(false);
        } else {
          this.close.emit(false);
        }
      }
    });
  }

  unselectAll(): void {
    if (this.globalService.windowsScreenWidth.value < 989) {
      this.categories.forEach(cat => {
        cat.isSelected = true;
        cat.children.forEach(cat1 => {
          cat1.isSelected = false;
          cat.children.forEach(cat2 => {
            cat2.isSelected = false;
          });
        });
      });
      this.secondLevelCategoriesIsSelected = false;
    } else {
      this.categories.forEach(cat => {
        cat.isSelected = false;
        cat.children.forEach(cat1 => {
          cat1.isSelected = false;
          cat.children.forEach(cat2 => {
            cat2.isSelected = false;
          });
        });
      });

    }
    this.categoriesIsSelected.emit(false);
    this.phoneBeginSelection = false;

  }

  getCategoryRouterLinkUrl(_categories: Category[]): string {
    let url = '';
    if (_categories) {
      let categories: number[] = [];
      url = '/article/list';
      if (_categories.length == 1) {
        // this.getCategories(_categories[0]).forEach((c) => {
        //   categories.push(c.id);
        // });
        categories.push(_categories[0].id);
      } else {
        categories = _categories.map(x => x.id);
      }
      if (categories.length > 0 && categories.filter(x => x == -1).length < categories.length) {
        url = [url, categories.join('/')].join('/');
      } else {
        url = [url, 'allpromotion'].join('/');
      }
    }
    return url;
  }

  categoryClick(categories: Category[] = []) {
    this.unselectAll();
    this.categoriesIsSelected.emit(false);
    this.phoneBeginSelection = false;
    this.closeMenu();

  }

  public closeMenu(): void {
    const self = this;
    self.menuisOpen = false;
    self.close.emit(false);

  }

  getCategories(rootCat: Category, cat: Category = null): Category[] {
    let cats: Category[] = [];
    if (this.categories != undefined && this.categories.length > 0) {
      if (rootCat == null) {
        cats = this.categories;
      } else {
        if (cat == null) {
          cats = this.categories.find(p => p.code == rootCat.code).children;
        } else {
          cats = this.categories.find(p => p.code == rootCat.code)
            .children.find(p => p.code == cat.code).children;
        }
      }
    }
    return cats;
  }


  selectCategory(categories: Category[]) {
    if (this.globalService.windowsScreenWidth.value > 989) {
      if (categories != undefined && categories.length > 0) {
        this.unselectAll();

        categories.forEach(a => a.isSelected = true);
        this.selectedCategories = categories;
        if (categories.length == 1) {
          if (categories[0].children.length != undefined && categories[0].children.length > 0) {
            categories[0].children[0].isSelected = true;
          }
        }
      }
    }
  }

  selectMainCategoryForPhone(categorie: Category) {

    if (this.globalService.windowsScreenWidth.value < 989) {
      this.secondLevelCategoriesIsSelected = true;
      if (categorie != undefined) {

        this.categories.forEach(c => c.isSelected = false);
        const locatCategories = this.categories.find(c => c.id == categorie.id);
        if (locatCategories.children != null && locatCategories.children.length > 0) {
          locatCategories.children.forEach(cat => {
            cat.isSelected = true;
            if (cat.children != null && cat.children.length != null) {
              cat.children.forEach(c => c.isSelected = true);
            }
          });
          this.phoneBeginSelection = true;
          this.categoriesselected = categorie;
          this.categoriesIsSelected.emit(true);
        }
      }
    }
  }

  reload() {
    this.sharedCategoryService.getCategories().subscribe(Categories => {
      this.init(Categories);
      this.sharedCategoryService.getCategoriesPromotional().subscribe(promoCats => {
        this.initPromotional(promoCats);
        this.loadFinished.emit(true);
      }, (ex) => this.loadFinished.emit(false));
    }, (ex) => this.loadFinished.emit(false));
  }

  init(categories: Category[]) {
    if (this.globalService.windowsScreenWidth.value < 989) {
      if (categories != null && categories.length > 0) {
        categories.forEach(categorie => {
          categorie.isSelected = true;
          if (categorie.children != null && categorie.children.length > 0) {
            categorie.children.forEach(cat => {
              cat.isSelected = false;
              if (cat.children != null && cat.children.length > 0) {
                cat.children.forEach(c => c.isSelected = false);
              }
            });
          }
        });
      }
    }
    this.categories = categories;

    this.sharedCategoryService.saveCategories(this.categories);
    this.initAnimations();
  }

  initAnimations() {

    const self = this;
    self.JQuery = $(this.elementRef.nativeElement);
    if (self.globalService.windowsScreenWidth.value < 989) {
      self.JQuery.find('.sous-nav').removeClass('closed');
    }
    self.JQuery.find('.category-container').mouseenter(function() {
      if (self.globalService.windowsScreenWidth.value > 989) {
        self.menuisOpen = true;
        self.close.emit(true);
        self.JQuery.find('.sous-nav').removeClass('closed');
      }


    });
    self.JQuery.find('.category-container').mouseleave(function() {
      // self.JQuery.find('.sous-nav').removeClass('display-block');
      if (self.globalService.windowsScreenWidth.value > 989) {
        self.JQuery.find('.sous-nav').addClass('closed');
        self.menuisOpen = false;
        self.close.emit(false);
      }

    });

  }

  initPromotional(categories: Category[]) {
    const category: Category = new Category();
    category.id = -1;
    category.code = '-1';
    category.codeParent = null;
    category.Description = 'Les Promotions de petit Bateau!';
    category.isSelected = false;
    category.libelle = 'Bonnes Affaires';

    const category2: Category = new Category();
    category2.id = -1;
    category2.code = '-1';
    category2.codeParent = null;
    category2.Description = 'Les Promotions de petit Bateau!';
    category2.isSelected = false;
    category2.libelle = 'Bonnes Affaires';
    let cats: Category[] = [];
    if (this.globalService.windowsScreenWidth.value < 989) {
      category.isSelected = true;
      category2.isSelected = true;
    } else {
      category.isSelected = false;
      category2.isSelected = false;
    }

    cats = categories;
    for (const c of cats) {
      c.libelle = c.libelle.toUpperCase();
    }
    category2.children = cats;

    category.children = [];

    category.children.push(category2);
    const index = this.categories.findIndex(x => x.id == category.id);
    if (index >= 0) {
      this.categories.splice(index, 1);
    }
    if (category.children != null && category.children.length > 0) {
      category.children.forEach(cat => {
        cat.isSelected = false;
        cat.children.forEach(cat1 => {
          cat1.isSelected = false;
          cat.children.forEach(cat2 => {
            cat2.isSelected = false;
          });
        });
      });
    }
    this.categories.push(category);
    this.sharedCategoryService.saveCategories(this.categories);
    this.initAnimations();
  }

}
