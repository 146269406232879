import { Component, OnInit } from '@angular/core';
import { SharedAccountService } from 'src/app/shared-module/services/account/shared.account.service';
import { SharedArticleService } from 'src/app/shared-module/services/shared.article.service';
import { SharedShoppingCartService } from 'src/app/shared-module/services/shared.shopping.cart.service';

@Component({
  selector: 'app-shopping-cart-icon',
  templateUrl: './shopping-cart-icon.component.html'
})
export class ShoppingCartIconComponent implements OnInit {

  sum = 0;
  numberOfItems = 0;



  constructor(    public sharedShoppingCartService: SharedShoppingCartService,
                  public sharedArticleService: SharedArticleService) { }


  ngOnInit() {

    this.sharedShoppingCartService.CurrentBasket.subscribe(basket => {
      const commandes = (basket && basket.commandes && basket.commandes.length > 0) ? basket.commandes : [];
      this.sum = this.sharedArticleService.getSum(
        this.sharedArticleService.getCmdArticles(commandes)
      );
      this.numberOfItems = 0;
      commandes.forEach(x => this.numberOfItems += x.quantity);

    });

  }

}
