import { Injectable } from '@angular/core';
import { Dimension, DimensionItems } from '../models/articles';
import { HttpClient } from '@angular/common/http';
import { WS_URL } from 'src/environments/environment';


@Injectable()
export class DimensionService {
    localstorageLibelle = 'dimentionsItems';

    constructor(private http: HttpClient) { }

    getDimensions(): Promise<Dimension[]> {
        return this.http.get(WS_URL.dimension.getAll).toPromise()
            .then(this.getResponses)
            .catch(this.handleError);
    }

    private getResponses(response: Response) {
        return response.json();
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }
    public addSelectedDimentionToLocal(dims: Dimension[], priceMin: number, priceMax: number, url: String) {
        let dimensionItems: DimensionItems = new DimensionItems();
        dimensionItems.dims = dims;
        dimensionItems.priceMax = priceMax;
        dimensionItems.priceMin = priceMin;
        dimensionItems.url = url;
        if (dims != undefined && dims.length > 0) {
            sessionStorage.setItem(this.localstorageLibelle, JSON.stringify(dimensionItems));
        }

    }
    removeSelectedDimentionFromLocal() {
        sessionStorage.removeItem(this.localstorageLibelle);
    }

    getSelectedDimentionFromLocal(): DimensionItems {
        let dims: DimensionItems = new DimensionItems();
        if (sessionStorage.getItem(this.localstorageLibelle) != undefined) {
            dims = JSON.parse(sessionStorage.getItem(this.localstorageLibelle));
        }
        return dims;
    }



    formatDimentionLibelle(dimLibelle: string): string {
        const dimList: string [] =  dimLibelle.trim().split(/\s+/);

        let res = dimList.join(' ');

        if (dimList.length > 2) {
            res = dimList[0];
        }
        return res;



    }
}
