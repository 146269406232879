import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SliderItem } from '../../models/core/SliderItem';


@Injectable()
export class ServiceGarantiesSiderService {
    public getSliderItems(): Observable<SliderItem[]> {
        const list: SliderItem[] = [{
            id: 0,
            imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dwa47d57ef/images/Pages_contenu/2018/Services/PC_Services/IMAGES%20DESKTOP/livraison-en-boutique.png',
            title: 'SUIVI DE COMMANDE',
            description: 'Ça y est, votre colis est en route ! Pour le suivre en temps réel, rendez-vous dans l’espace MON COMPTE.',
            SliderSection_Id: 0
        }, {
            id: 1,
                imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dwa47d57ef/images/Pages_contenu/2018/Services/PC_Services/IMAGES%20DESKTOP/livraison-en-boutique.png',
                title: 'LIVRAISON EN BOUTIQUE (GRATUIT)',
                description: 'Vous pouvez récupérer votre colis dans un délai de 2 jours ouvrés à compter du paiement de votre commande dans une sélection de boutiques. Livraison en boutique non valable en période de soldes ou VP. ',
            SliderSection_Id: 0
        }, {
            id: 2,
                imageUrl: 'https://www.petit-bateau.fr/on/demandware.static/-/Library-Sites-SharedPetitBateauLibrary/default/dwbd9890ae/images/Pages_contenu/2018/Services/PC_Services/IMAGES%20DESKTOP/livraison-a-domicile.png',
                title: 'LIVRAISON A DOMICILE (8,500dt)',
                description: 'Bénéficiez de la livraison à domicile dans un délai de 5 jours ouvrés via Tunisia Express à compter du paiement de votre commande.',
            SliderSection_Id: 0
        }];
        return of(list);
    }


}
