import { Injectable } from '@angular/core';

import { Article } from '../models/articles';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SharedAccountService } from './account/shared.account.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WS_URL } from 'src/environments/environment';
import { WishList } from '../models/wish-list/wish-list';
import { GlobalsService } from './globals.service';
import { GoogleAnalyticsService } from './google-analytics.service';

@Injectable()
export class WishListService {
    added: boolean;
    removed: boolean;

    localstorageLibelle = 'wishedItems';



    constructor(public http: HttpClient,
        public accountService: SharedAccountService,
        private googleAnalyticsService: GoogleAnalyticsService) {
    }

    add(userEmail: string, article: Article): Observable<boolean> {
        let params: HttpParams = new HttpParams();
        params = params.append('Email', userEmail);
        return this.http.post<boolean>(WS_URL.wishList.addToWishListUrl, article, {
            params
        });
    }

    updateWishList(userEmail: string, article: Article[]): Observable<boolean> {
        let params: HttpParams = new HttpParams();
        params = params.append('Email', userEmail);
        return this.http.post<boolean>(WS_URL.wishList.updateWishListUrl, article, {
            params
        }).pipe(map(res => this.added = res));
    }
    remove(userEmail: string, article: Article): Observable<boolean> {
        let params: HttpParams = new HttpParams();
        params = params.append('Email', userEmail);
        return this.http.post<boolean>(WS_URL.wishList.removeFromWishListUrl, article, {
            params
        }).pipe(map(res => this.removed = res));

    }

    removeAll(userEmail: string): Observable<boolean> {
        let params: HttpParams = new HttpParams();
        params = params.append('Email', userEmail);
        return this.http.get<boolean>(WS_URL.wishList.removeFromWishListAllUrl, { params })
            .pipe(map(res => this.removed = res));
    }

    getAll(userEmail: string): Observable<WishList> {
        let params: HttpParams = new HttpParams();
        params = params.append('Email', userEmail);
        return this.http.get<WishList>(WS_URL.wishList.getAllUrl, { params });
    }
    sendEmail(wishList: WishList): any {
        return this.http
            .post(WS_URL.wishList.sendEmailUrl, wishList);
    }

    addWishListElementLocal(article: Article) {

        const wishListItems = this.getWishListItemsLocal();
        if (wishListItems.find(a => a.codeBarre == article.codeBarre) == null) {
            wishListItems.push(article);
            this.googleAnalyticsService.addWishListEmitter(article.libelle);

            localStorage.removeItem(this.localstorageLibelle);
            localStorage.setItem(this.localstorageLibelle, JSON.stringify(wishListItems));
        }
    }

    addWishListElementsLocal(articles: Article[]) {
        let hasChange: boolean;
        const wishListItems = this.getWishListItemsLocal();
        if (articles != undefined && articles.length > 0) {
            articles.forEach(article => {
                if (wishListItems.find(a => a.codeBarre == article.codeBarre) == null) {
                    wishListItems.push(article);
                    hasChange = true;
                }
            });
        }
        if (hasChange == true) {
            localStorage.removeItem(this.localstorageLibelle);
            localStorage.setItem(this.localstorageLibelle, JSON.stringify(wishListItems));
        }
    }

    removeWishListElementLocal(article: Article) {

        const wishListItems = this.getWishListItemsLocal();
        const index = wishListItems.findIndex(a => a.codeBarre == article.codeBarre);
        if (index > -1) {
            wishListItems.splice(index, 1);
            localStorage.removeItem(this.localstorageLibelle);
            localStorage.setItem(this.localstorageLibelle, JSON.stringify(wishListItems));
        }
    }

    getWishListItemsLocal(): Article[] {
        let wishList: Article[] = [];
        if (localStorage.getItem(this.localstorageLibelle) != undefined) {
            wishList = JSON.parse(localStorage.getItem(this.localstorageLibelle));
        }
        return wishList;
    }

    removeAllWishListLocal() {
        localStorage.removeItem(this.localstorageLibelle);
    }




}
