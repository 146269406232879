import { Injectable } from '@angular/core';
declare let gtag: Function;

@Injectable()
export class GoogleAnalyticsService {
    public eventEmitter(
        eventName: string,//"add_to_cart",
        eventCategory: string, //"shop"
        eventAction: string,//"cart"
        eventLabel: string = null,//click
        eventValue: number = null) {//10
        gtag('event', eventName, {
            eventCategory: eventCategory,
            eventLabel: eventLabel,
            eventAction: eventAction,
            eventValue: eventValue
        });
    }

    public searchEmitter(eventValue: string) {
        gtag('event', 'search', {
            eventCategory: 'engagement',
            eventLabel: 'search_term',
            eventValue: eventValue
        });
    }

    public addToCartEmitter(label: string, qty: number) {
        gtag('event', 'add_to_cart', {
            eventCategory: 'ecommerce',
            eventLabel: label,
            eventValue: qty
        });
    }

    public addWishListEmitter(label: string) {
        gtag('event', 'add_to_wishlist', {
            eventCategory: 'ecommerce',
            eventLabel: 'wish_list',
            eventValue: label
        });
    }

      public loginEmitter(username: string) {
        gtag('event', 'login', {
            eventCategory: 'engagement',
            eventLabel: 'method',
            eventValue: username
        });
    }

    

}