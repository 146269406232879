import { Customer } from './customer';

export class ChangePasswordModel {
    oldpassword: string;
    newpassword: string;
    confirmpassword: string;
}

export class ModifyEmailModel {
    account: {
        confirmnewEmail: string;
        newEmail: string;
    };
    customer: Customer;
    password: string;
}
