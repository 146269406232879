

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { WS_URL } from 'src/environments/environment';
import { ChangePasswordModel, ModifyEmailModel, User, Login, Customer } from '../../models/account';
import { HttpResult } from '../../models/core/http-result';
import { map } from 'rxjs/operators';
import { GlobalsService } from '../globals.service';
import { SharedCustomerService } from '../shared.customer.service';
import { GoogleAnalyticsService } from '../google-analytics.service';

@Injectable({ providedIn: 'root' })
export class SharedAccountService {

    public isConnectedUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public accountStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);



    constructor(public http: HttpClient,
                public router: Router,
                public globals: GlobalsService,
                private googleAnalyticsService : GoogleAnalyticsService ) {
        this.isConnectedUser.next(this.isUserConnected());
    }

    isUserConnected(): boolean {
        return (localStorage.getItem('access_token') != undefined);
    }

    display(value: boolean) {
        this.accountStatus.next(value);
    }


    LogOut() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('username');
        localStorage.removeItem('customer');
        localStorage.removeItem('userid');
        localStorage.removeItem('userRoles');
        localStorage.removeItem('currentBasket');
        localStorage.removeItem('expiration-date');
        this.isConnectedUser.next(false);
        this.router.navigate(['/home']);
    }

    getConnectedUsername(): string {
        return localStorage.getItem('username');
    }

    changePassword(changepasswordmodel: ChangePasswordModel): Observable<Response> {
        return this.http
            .post<Response>(WS_URL.account.changepasswordUrl, changepasswordmodel);

    }

    changeEmail(modifyEmailModel: ModifyEmailModel): Observable<Response> {
        return this.http
            .post<Response>(WS_URL.account.modifyEmailUrl, modifyEmailModel);
    }


    resetPassword(email: string, token: string, password: string): Observable<HttpResult> {
        return this.http
            .post<HttpResult>(WS_URL.account.resetPasswordTokenUrl, {
                email,
                token,
                password
            });
    }

    getUserByEmail(email: any): Observable<any> {
        let params = new HttpParams();
        params = params.set('email', email);

        return this.http
            .get(WS_URL.account.getUserByEmailUrl, { params });

    }

    ForgotPaswordToken(myEmail: string): Observable<HttpResult> {
        const user = new User();
        user.Email = myEmail;
        return this.http.post<HttpResult>(WS_URL.account.forgotPasswordTokenUrl, user);

    }

    GetToken(login: Login): Observable<any> {
        return this.http
            .post(WS_URL.auth.token,
                `grant_type=password&UserName=${login.Email}&password=${login.Password}&client_id=${this.globals.clientId}`)
            .pipe(map((res) => {
                this.googleAnalyticsService.loginEmitter(login.Email);
                this.getTokenResult(res);

            }));
    }


    getTokenResult(res: any): boolean {
        if (res.access_token != undefined && res.userName) {
            localStorage.setItem('access_token', res.access_token);
            localStorage.setItem('username', res.userName);
            localStorage.setItem('userid', res.userId);
            localStorage.setItem('userRoles', res.userRoles);
            this.isConnectedUser.next(true);
        } else {
            this.isConnectedUser.next(false);
        }
        return this.isConnectedUser.value;
    }

    checkPassword(password: string): Observable<any> {
        return this.http.post(WS_URL.account.checkPasswordUrl, {
            password
        });
    }

    emailExists(email: string): Observable<boolean> {
        let params = new HttpParams();
        params = params.set('email', email);

        return this.http
            .get<boolean>(WS_URL.account.emailExistsUrl, { params });
    }

    Register(customer: Customer): Observable<HttpResult> {
        const user = new User();
        user.Email = 'Administrator@petitbateau.tn';
        user.Password = 'Arion123';
        user.roles = ['Admin'];
        user.UserName = user.Email;
        return this.http
            .post<HttpResult>(WS_URL.account.registerUrl, customer);
    }


    searchUserByPhoneNumber(phoneNumber: string): Observable<boolean> {
        return this.http
            .get<boolean>(WS_URL.account.searchUserByPhoneNumberUrl + '?phoneNumber=' + phoneNumber);
    }

    public confirmCustomerEmail(cryptidCustomer: string): Observable<HttpResult> {

        return this.http
            .get<HttpResult>(WS_URL.account.confirmCustomerEmailUrl + '?cryptidCustomerId=' + cryptidCustomer);

    }

    getUserId(): string {
        if (this.isConnectedUser.value == true) {
            return localStorage.getItem('userid');
        }
        return '';
    }

}
