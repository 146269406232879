import { Article } from '../articles';

export class SliderItem {

    article: Article;
    title = '';

    static equals(a: SliderItem, b: SliderItem): boolean {
        return a == b || (a != null && a.article != null && b != null && b.article != null &&
            a.article.codeArticle == b.article.codeArticle && a.title == b.title);
    }


}
