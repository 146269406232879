import { Article } from './article';
import { Category } from './category';
import { Tarif } from './tarif';
import { Promotion } from './promotion';



export class Family {
    id: number;
    code: string;
    libelle: string;
    codeArticle: string;
    articles: Article[];
    categories: Category[];
    tarif: Tarif;
    promotion: Promotion;


}
