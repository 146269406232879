export class Dimension {
    constructor(code: string, codeType: string, codeFamily: string) {
        this.code = code;
        this.codeType = codeType;
        this.codeFamily = codeFamily;
    }
    code = '';
    libelle = '';
    codeType = '';
    libelleType = '';
    codeFamily = '';
    libelleFamily = '';
    abregeFamily = '';
    isSelected = false;
    imgReference = '';
    equals(dim: Dimension): boolean {
        return (dim.code == this.code && dim.codeFamily == this.codeFamily && dim.codeType == this.codeType);
    }

}
