

export class Collection {

    public id?: number;
    public saison?: string;
    public startDate?: Date;
    public endDate?: Date;
    public wsId?: string;
}
