import { Dimension } from './dimension';
import { Category } from './category';


export class Promotion {

    id: number;
    codeBarre: string;

    codeArticle: string;
    price: number;
    remise: number;
    calculRemise: number;
    startDate: Date;
    endDate: Date;

}
