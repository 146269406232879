import { Dimension } from './dimension';

export class ArticleStoreAvailableQty {
    articleFamilyCode: string;
    articleFamilyAvailableQty: number;
    storeId: string;
    storeName: string;
    availableQty: number;
    dimension1: string;
    dimension2: string;
    dimension3: string;
    dimension4: string;
    dimension5: string;
    articleBareCode: string;
}
