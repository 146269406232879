import { Injectable } from '@angular/core';
import { Store, ArticleAvailableQty } from '../models/shopping-cart';
import { Article } from '../models/articles';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WS_URL } from 'src/environments/environment';

@Injectable()
export class DeliveryService {

    constructor(private http: HttpClient) { }

    getStoresByType(): Observable<Store[]> {
        return this.http
            .get<Store[]>(WS_URL.shoppingCart.getSucStoresUrl + '?type=SUC');
    }
    getAvilableQttyList(storeId: string, warehouseId: string, article: Article[]): Observable<ArticleAvailableQty[]> {
        return this.http
            .post<ArticleAvailableQty[]>(WS_URL.shoppingCart.getAvilableQttyListUrl + '?storeId=' + storeId + '&warehouseId=' + warehouseId, article);
    }

    validatePayment(acknoledgmentUrl): Observable<any> {


        // let options = { headers: new Headers() };
        // options.headers.set("Access-Control-Allow-Origin", "*");
        // options.headers.set("Access-Control-Allow-Headers", "Cache-Control, Pragma, Origin, Authorization, Content-Type, X-Requested-With");
        // options.headers.set("Access-Control-Allow-Methods", "GET, PUT, POST");
        return this.http.get(acknoledgmentUrl);
    }

    formatDate(date: Date): string {
        let dd = date.getDate();
        let MM = date.getMonth() + 1; // January is 0!
        let yyyy = date.getFullYear();
        let hh = date.getHours();
        let mm = date.getMinutes();
        let ss = date.getSeconds();
        let currentMonth;
        let currentDay;
        let currentHours;
        let currentMinutes;
        let currentSeconds;
        if (dd < 10) {
            currentDay = '0' + dd;
        } else {
            currentDay = dd.toString();
        }

        if (MM < 10) {
            currentMonth = '0' + MM;
        } else {
            currentMonth = MM.toString();
        }
        if (hh < 10) {
            currentHours = '0' + hh;
        } else {
            currentHours = hh.toString();
        }

        if (hh < 10) {
            currentHours = '0' + hh;
        } else {
            currentHours = hh.toString();
        }
        if (mm < 10) {
            currentMinutes = '0' + mm;
        } else {
            currentMinutes = mm.toString();
        }

        if (ss < 10) {
            currentSeconds = '0' + ss;
        } else {
            currentSeconds = ss.toString();
        }

        return currentDay + currentMonth + yyyy + currentHours + currentMinutes + currentSeconds;
    }

    registerPayment(data: any): Observable<any> {

        return this.http.post<any>(WS_URL.shoppingCart.PaymentCredentialUrlAPI, data);
    }
}
